export default {
  path: "/:appId/omni/customers",
  name: "omni.customers",
  component: () => import("@/views/customer/pages/CustomerMain.vue"),
  redirect: { name: "omni.customers.list" },
  props: true,
  meta: {
    title: "Customer",
  },
  children: [
    // {
    //   path: "list",
    //   name: "omni.customers.list",
    //   component: () => import("@/views/customer/pages/CustomerList.vue"),
    //   meta: {
    //     title: "Customer List",
    //   },
    // },
    // {
    //   path: "list/segments/:type/:id",
    //   name: "omni.customers.list.segments",
    //   component: () => import("@/views/customer/pages/CustomerSegment.vue"),
    //   meta: {
    //     title: "Customer Segment",
    //   },
    // },
    // {
    //   path: "/:appId/customers/main",
    //   name: "customers.main",
    //   component: () => import("@/views/customer/pages/CustomerList.vue"),
    //   meta: { title: "Customers" },
    // },
    //   {
    {
      path: "/:appId/omni/customers",
      name: "omni.customers.list",
      component: () => import("@/views/customer/pages/CustomerList.vue"),
      meta: {
        title: "Customer",
      },
    },
    {
      path: "/:appId/omni/customers/:filter",
      name: "omni.customers.list.params",
      component: () => import("@/views/customer/pages/CustomerList.vue"),
      meta: {
        title: "Customer",
      },
    },
    {
      path: "/:appId/omni/customers/profile/:customer_id",
      name: "omni.customers.panel",
      component: () => import("@/views/customer/pages/CustomerPanel.vue"),
      meta: { title: "Customer Panel" },
    },
    {
      path: "/:appId/omni/customers/create",
      name: "omni.customers.create",
      component: () => import("@/views/customer/pages/CreateCustomer.vue"),
      meta: { title: "Create New Customer" },
    },
    {
      path: "/:appId/omni/mailbox",
      name: "omni.mail.list",
      component: () => import("@/views/mail/pages/MailMain.vue"),
      meta: {
        title: "Mailbox",
      },
    },
  ],
};
// const customerRoute = [
//   {
//     path: "/:appId/omni/customers",
//     name: "omni.customers.list",
//     component: () => import("@/views/customer/pages/CustomerList.vue"),
//     meta: {
//       title: "Customer",
//     },
//   },
//   {
//     path: "/:appId/omni/customers/profile/:customer_id",
//     name: "omni.customers.panel",
//     component: () => import("@/views/customer/pages/CustomerPanel.vue"),
//     meta: { title: "Customer Panel" },
//   },
//   {
//     path: "/:appId/omni/customers/create",
//     name: "omni.customers.create",
//     component: () => import("@/views/customer/pages/CreateCustomer.vue"),
//     meta: { title: "Create New Customer" },
//   },
//   {
//     path: "/:appId/omni/mailbox",
//     name: "omni.mail.list",
//     component: () => import("@/views/mail/pages/MailMain.vue"),
//     meta: {
//       title: "Mailbox",
//     },
//   },
// ];

// export default customerRoute;
