import { isObject } from "lodash";
let urlTemp =
  window.location.hostname.split(".")[0] == "damcorp"
    ? process.env.VUE_APP_DAMCORP_URL
    : process.env.VUE_APP_APP_URL;
export const axiosConfig = {
  baseURL: process.env.VUE_APP_OMNICHANNEL_URL,
  timeout: 240000,
  headers: {
    userId: JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user")).id
      : "",
    token: JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user")).token.access_token
      : "",
  },
};

export function axiosInitialize(axios, store) {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      store.dispatch("loading/updateIsLoading", true);

      return config;
    },
    function (error) {
      // Do something with request error
      store.dispatch("loading/updateIsLoading", true);

      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      store.dispatch("loading/updateIsLoading", false);
      return response;
    },
    function (error) {
      // Do something with response error
      store.dispatch("loading/updateIsLoading", false);

      // console.log("error", error);
      if (isObject(error)) {
        if (error.response) {
          if (error.response.status == 401) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " +
              JSON.parse(localStorage.getItem("user")).token.access_token;
            axios.post(process.env.VUE_APP_AUTH_URL + "/api/logout").then(
              () => {
                localStorage.removeItem("user");
                window.location.href = urlTemp;
              }
              // (window.location.href = process.env.VUE_APP_AUTH_URL + "/login")
            );
          }

          if (error.response.data.statusCode == 4001) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " +
              JSON.parse(localStorage.getItem("user")).token.access_token;
            axios.post(process.env.VUE_APP_AUTH_URL + "/api/logout").then(
              () => {
                localStorage.removeItem("user");
                // window.location.href = process.env.VUE_APP_APP_URL;
                window.location.href = urlTemp;
              }
              // (window.location.href = process.env.VUE_APP_AUTH_URL + "/login")
            );
          }

          if (process.env.VUE_APP_OMNICHANNEL_ENV != "production") {
            // eslint-disable-next-line no-console
            console.log(error.response);
          }
        }
      }

      return Promise.reject(error);
    }
  );
}

export function isGuest() {
  if (!localStorage.getItem("user")) {
    window.location.href = urlTemp;
    // window.location.href = process.env.VUE_APP_APP_URL;
  }
}
