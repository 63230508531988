import { httpClientBot } from "@/libraries";
const state = {
  entities: [],
};

const getters = {
  entities(state) {
    return state.entities;
  },
};

const mutations = {
  SET_ENTITIES: (state, payload) => {
    state.entities = payload;
  },
};

const actions = {
  async store(context, payload) {
    let response = await httpClientBot.post(`/entities`, payload);
    console.log("response entities :", response);
    context.commit("SET_ENTITIES", response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
