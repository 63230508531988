<template>
  <div class="card-body">
    <h5 class="card-title mb-3">Integrate With Tokopedia</h5>
    <div class="row row-cols-lg-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
      <div class="col" v-for="(i, key) in list" :key="key">
        <div class="card card-body">
          <div
            class="text-end dropdown"
            style="position: absolute; right: 20px"
          >
            <div
              class="form-check form-switch form-switch-right form-switch-md"
            >
              <!-- <edit button /> -->
              <span @click="toggleEdit(i)" class="to-pointer">
                <i class="ri-edit-line text-mute me-2"></i>
              </span>
              <!-- <delete button /> -->
              <span @click="handleDelete(i.id)" class="to-pointer">
                <i class="ri-delete-bin-6-line text-danger"></i>
              </span>
            </div>
          </div>

          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-xs">
                <div class="avatar-title rounded bg-primary fs-14">
                  <i class="ri-shopping-bag-fill" style="font-size: 1.6em"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="card-title mb-1">
                {{ truncateText(i.integration_data.IntegrationName, "11") }}
              </h5>
            </div>
          </div>

          <span class="card-timestamp text-muted">
            Added on {{ formatDate(i.created_at, "D MMM, Y LT") }}
          </span>
          <span class="card-timestamp text-muted mb-3">
            Last update {{ formatDate(i.updated_at, "D MMM, Y LT") }}
          </span>
          <base-button
            :class="{
              'btn-soft-danger': i.status,
              'btn-soft-secondary': !i.status,
            }"
            class="btn btn-sm"
            @click="updateStatusIntegration(i)"
            >{{ i.status ? "Deactivate" : "Activate" }}</base-button
          >
        </div>
      </div>
      <div class="col">
        <div class="card card-height-100">
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center"
          >
            <i
              class="ri-add-circle-fill pointer text-primary"
              style="font-size: 45px"
              @click="toggleCreate"
            ></i>
            <label class="text-primary">Add New Tokopedia</label>
          </div>
        </div>
      </div>
    </div>
    <!-- modal lama, dengan validasi di pojok kanan atas -->
    <!-- <integration-modal
      :show-modal="modal"
      :mode="mode"
      :key="key"
      :default-data="form"
      @hide="toggleHide"
    >
    </integration-modal> -->
  </div>
  <integration-modal
    :showModal="modal.create"
    :form="form"
    :modalTitle="`add ${this.form.channelName} Integration`"
    :isLoading="modal.loading"
    @onClose="modal.create = false"
    @onSubmit="create"
  />
  <integration-modal
    :showModal="modal.edit"
    :form="formEdit"
    :modalTitle="`Edit ${this.form.channelName} Integration`"
    :isLoading="modal.loading"
    @onClose="modal.edit = false"
    @onSubmit="update"
  />
</template>

<script>
import IntegrationModal from "./tokopedia/ConfigTokopediaIntegrationModal.vue";
// import moment from "moment";
// import { debounce, cloneDeep, orderBy } from "lodash";
import { httpClientOmni } from "@/libraries";
import axios from "axios";
export default {
  components: {
    IntegrationModal,
  },
  data() {
    return {
      // cloneDeep,
      // debounce,
      modal: {
        create: false,
        edit: false,
        delete: false,
        loading: false,
      },
      formEdit: {},
      form: {
        IntegrationName: "",
        appId: "",
        shopId: "",
        clientId: "",
        clientSecret: "",
        channelName: "tokopedia",
        status: false,
        integration: null,
        bot_id: null,
        business_unit_id: null,
        group_id: null,
      },
      list: [],
    };
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch("ticket/getBranchOffice"); //dipanggil disini jadi ntar modal kebuka langsung ada
    this.$store.dispatch("ticket/getAllGroupManagement", "");
  },
  methods: {
    async fetchData() {
      console.log(this.form.channelName);
      try {
        const response = await this.$axios.get(
          `api/${this.$store.getters.appId.hashed}/integrate`
        );
        let channelData = response.data.data.find(function (item) {
          return item.name == "tokopedia"; // cacad
        }).integration;
        this.list = channelData;
        console.log("list:", this.list);
      } catch (error) {
        this.errorToast(error);
      }
    },
    async handleDelete(id) {
      this.$swal
        .fire({
          title: `Are you sure, want to delete this ${this.form.channelName} integration ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Submit it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let response = await httpClientOmni.delete(
              `integration/delete/${id}`
            );
            this.successToast(response.data.message);
            this.fetchData();
          }
        });
    },
    async create(data) {
      console.log("data di create:", data);
      this.modal.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_OMNICHANNEL_URL}/api/webhook/tokopedia/checkShop`,
          data
        )
        .then((response) => {
          this.form.IntegrationName = data.IntegrationName;
          this.form.appId = data.appId;
          this.form.shopId = data.shopId;
          this.form.clientId = data.clientId;
          this.form.clientSecret = data.clientSecret;
          this.form.channelName = "tokopedia";
          this.form.status = false;
          this.form.integration = null;
          this.form.bot_id = data.bot_id ? data.bot_id : null;
          this.form.business_unit_id = data.business_unit_id
            ? data.business_unit_id
            : null;
          this.form.group_id = data.group_id ? data.group_id : null;
          this.onCreate();
        })
        .catch((e) => {
          this.modal.create = true;
          this.modal.loading = false;
          console.error("error submit : ", e.response.data.message);
          this.errorToast(e.response.data.message);
        });

      this.modal.loading = true;
    },

    async update(data) {
      console.log("data di update:", data);
      this.modal.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_OMNICHANNEL_URL}/api/webhook/tokopedia/checkShop`,
          data
        )
        .then((response) => {
          this.form.IntegrationName = data.IntegrationName;
          this.form.appId = data.appId;
          this.form.shopId = data.shopId;
          this.form.clientId = data.clientId;
          this.form.clientSecret = data.clientSecret;
          this.form.channelName = "tokopedia";
          this.form.status = false;
          this.form.integration = data.integration;
          this.form.bot_id = data.bot_id ? data.bot_id : null;
          this.form.business_unit_id = data.business_unit_id
            ? data.business_unit_id
            : null;
          this.form.group_id = data.group_id ? data.group_id : null;
          this.updateIntegration();
        })
        .catch((e) => {
          this.modal.loading = false;
          console.error("error update : ", e.response.data.message);
          this.errorToast(e.response.data.message);
        });

      this.modal.loading = true;
    },

    async onCreate() {
      console.log("on create integration", this.form);
      try {
        this.loading = true;
        const { data: response } = await this.$store.dispatch(
          "integration/saveIntegration",
          {
            ...this.form,
          }
        );
        console.log("balikan data:", response);
        this.emitter.emit("form:reset");
        this.successToast(response.message);
        this.modal.loading = false;
        this.modal.create = false;
        this.fetchData();
      } catch (error) {
        console.log("error data:".error);
        this.loading = false;
        this.errorToast("Failed to create integration tokopedia");
        this.modal.loading = false;
      }
    },

    toggleHide() {
      this.modal = false;
      this.fetchData();
    },

    toggleEdit(val) {
      this.setDefaultData(val);
      this.modal.edit = true;
    },
    toggleCreate() {
      this.modal.create = true;
    },
    setDefaultData(val) {
      this.formEdit.integration = val.id;
      this.formEdit.IntegrationName = val.integration_data.IntegrationName;
      this.formEdit.clientSecret = val.integration_data.clientSecret;
      this.formEdit.clientId = val.integration_data.clientId;
      this.formEdit.shopId = val.integration_data.shopId;
      this.formEdit.appId = val.integration_data.appId;
      this.formEdit.channelName = "tokopedia";
      this.formEdit.status = val.status;
      this.formEdit.bot_id = val.bot_id;
      this.formEdit.business_unit_id = val.business_unit_id;
      this.formEdit.group_id = val.group_id;

      this.formEdit.bot = val.bot;
      this.formEdit.business_unit = val.business_unit
        ? val.business_unit.branch_office
        : null;
      this.formEdit.group = val.group ? val.group.name : null;
    },
    updateStatusIntegration(data) {
      console.log("Datain aja:", data);
      this.form.IntegrationName = data.integration_data.IntegrationName;
      this.form.appId = data.integration_data.appId;
      this.form.shopId = data.integration_data.shopId;
      this.form.clientId = data.integration_data.clientId;
      this.form.clientSecret = data.integration_data.clientSecret;
      this.form.channelName = "tokopedia";
      this.form.integration = data.id;
      this.form.bot_id = data.bot_id ? data.bot_id : null;
      this.form.business_unit_id = data.business_unit_id
        ? data.business_unit_id
        : null;
      this.form.group_id = data.group_id ? data.group_id : null;
      this.form.status = this.form.status ? false : true;
      this.updateIntegration();
    },

    async updateIntegration() {
      console.log("Data edit awal:", this.form);
      try {
        const { data: response } = await this.$store.dispatch(
          "integration/updateIntegration",
          {
            ...this.form,
          }
        );
        if (response.success) {
          this.successToast(response.message);
          this.emitter.emit("form:reset");
          this.modal.loading = false;
          this.modal.edit = false;
          this.fetchData();
        }
      } catch (error) {
        this.modal.loading = false;
        console.log("error", error);
        this.errorToast("Failed to update integration tokopedia");
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
