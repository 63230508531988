<template>
  <div class="card-body">
    <h5 class="card-title mb-3">Integration With Email</h5>
    <div class="row row-cols-lg-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
      <div class="col" v-for="(val, key) in emailAccount" :key="key">
        <div class="card card-body set-max-size">
          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-xs">
                <div class="avatar-title rounded bg-primary fs-14">
                  <i class="ri-mail-fill fs-20"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <template v-if="val.integration_data.username">
                <span v-if="val.integration_data.username.length > 15">
                  {{ val.integration_data.username.substr(0, 15) }}..
                </span>
                <span v-else> {{ val.integration_data.username }} </span>
              </template>
            </div>
            <div class="flex-shrink-0 align-self-start" data-v-42c37e09="">
              <div class="dropdown" data-v-42c37e09="">
                <button
                  class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-v-42c37e09=""
                >
                  <i class="ri-more-2-fill align-bottom" data-v-42c37e09=""></i>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end"
                  data-v-42c37e09=""
                  style=""
                >
                  <a
                    class="dropdown-item"
                    href="#"
                    data-v-42c37e09=""
                    @click="handleDelete(val.id)"
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-end dropdown">
            <div
              class="form-check form-switch form-switch-right form-switch-md pe-0"
            >
              <Toggle v-model="val.status" @change="updateStatus(val)" />
            </div>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <template v-if="val.is_primary == false">
              <small class="fw-500"><i> Set as primary </i></small>
              <button
                class="btn btn-danger btn-sm mb-1"
                @click="toggleSetPrimary(val.id)"
              >
                <i class="ri-flag-fill" v-if="isSetPrimary == false"></i>
                <b-spinner small v-else></b-spinner>
              </button>
            </template>
            <template v-else>
              <small class="fw-500 text-danger mb-3"
                ><i> has ben set to primary </i></small
              >
            </template>
          </div>
          <button class="btn btn-primary btn-sm mt-1" @click="toggleEdit(val)">
            Configuration
          </button>
        </div>
      </div>
      <div class="col">
        <div class="card card-height-100">
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center"
          >
            <i
              class="ri-add-circle-fill pointer"
              style="font-size: 45px"
              @click="toggleCreate"
            ></i>
            <label>Add</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :showModal="modal.create"
    :modalTitle="'Add Email Integration'"
    :isLoading="modal.loading"
    :type="'create'"
    :businessUnit="business_unit"
    @onClose="modal.create = false"
    @onSubmit="create"
  />
  <Modal
    :showModal="modal.edit"
    :form="formEdit"
    :modalTitle="'Config Email Integration'"
    :isLoading="modal.loading"
    :businessUnit="business_unit"
    :type="'update'"
    @onClose="modal.edit = false"
    @onSubmit="update"
  />
</template>

<script>
import Modal from "@/views/integration/components/email/ModalEmail.vue";
import Toggle from "@vueform/toggle";
import { httpClientOmni } from "@/libraries";
import { mapGetters } from "vuex";

export default {
  components: {
    Modal,
    Toggle,
    // "list-whatsapp-account": ListWhatsappAccount,
    // "add-whatsapp-account": AddWhatsappAccount,
    // "detail-whatsapp-account": DetailWhatsappAccount,
  },
  data() {
    return {
      // loading: false,
      isSetPrimary: false,
      modal: {
        create: false,
        edit: false,
        delete: false,
        loading: false,
      },
      formEdit: {},
      form: {
        username: "",
        email: "",
        password: "",
        imap_server: "",
        imap_port: "",
        imap_security_type: "",
        imap_path_prefix: null,
        smtp_server: "",
        smtp_port: "",
        smtp_security_type: "",
        projectId: "",
        business_unit_id: "",
      },
      integration: {
        id: null,
        app_id: null,
        channel_id: null,
      },
      isShow: "list",
      isPage: "Add Email Account",
      emailAccount: [],
      postfixWebhook: "",
    };
  },
  computed: {
    ...mapGetters({
      business_unit: "ticket/getBranchOffice",
    }),
    selectedService() {
      return this.form.apiService;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // this.fetchData();
  },
  async created() {
    this.getEmailAccount();
    this.$store.dispatch("ticket/getBranchOffice");
  },
  watch: {},
  methods: {
    async create(data) {
      this.modal.loading = true;
      try {
        let formData = {
          ...data,
          status: true,
        };
        let response = await httpClientOmni.post(
          `email/add-email-integration`,
          formData
        );
        this.form.status = true;
        this.successToast(response.data.message);
        this.emitter.emit("form:reset");
        this.modal.loading = false;
        this.modal.create = false;
        this.getEmailAccount();
      } catch (error) {
        this.modal.create = false;
        this.modal.loading = false;
        console.error("error submit : ", error);
        this.errorToast(this, error);
      }
    },
    async updateStatus(email) {
      // console.log('update status : ', email);
      try {
        let formData = {
          id: email.id,
          status: email.status,
          updateStatus: true,
        };
        let response = await httpClientOmni.post(
          `email/update-email-integration`,
          formData
        );
        // console.log('response : ', response.data)
        this.successToast(response.data.message);
      } catch (error) {
        this.errorToast(this, error);
      }
    },
    async handleDelete(id) {
      this.$swal
        .fire({
          title: "Are you sure, want to delete this integration ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Submit it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let response = await httpClientOmni.delete(
              `integration/delete/${id}`
            );

            this.successToast(response.data.message);
            this.getEmailAccount();
          }
        });
    },
    async toggleSetPrimary(id) {
      try {
        this.isSetPrimary = true;
        let response = await httpClientOmni.post(`email/set-primary`, {
          id: id,
        });
        this.successToast(response.data.message);
        this.getEmailAccount();
        this.isSetPrimary = false;
      } catch (error) {
        this.isSetPrimary = false;
        this.errorToast(this, error);
      }
    },
    async update(data) {
      // let validated = await this.$validator.validateAll();
      // console.log("this.form : ", this.form);
      // console.log("this.webhookUrl : ", this.webhookUrl);
      // if (validated) {
      this.modal.loading = true;
      try {
        let formData = {
          ...data,
          channelName: "email",
          status: true,
          id: this.integration.id,
        };
        let response = await httpClientOmni.post(
          `email/update-email-integration`,
          formData
        );
        this.form.status = true;
        this.status = true;
        this.emitter.emit("form:reset");

        this.successToast(response.data.message);
        this.modal.loading = false;
        this.modal.edit = false;
        this.getEmailAccount();
      } catch (error) {
        this.modal.loading = false;
        this.modal.edit = false;
        this.errorToast(error);
      }
      // } else {
      //   ToastHelper.forbiddenToast(this, "you must fill the form first");
      // }
    },
    async getEmailAccount() {
      let response = await httpClientOmni.get(`email/get-integration`);
      response = response.data.data;
      this.emailAccount = response;
      console.log("response email: ", this.emailAccount);
    },
    toggleCreate() {
      this.modal.create = true;
    },
    toggleEdit(value) {
      console.log("data", this.formEdit);
      this.integration.id = value.id;
      this.integration.app_id = value.app_id;
      this.integration.channel_id = value.channel_id;
      this.postfixWebhook = value.integration_data.apiService;
      this.formEdit = value.integration_data;
      this.formEdit.webhookUrl = this.webhookUrl;
      this.formEdit.forward_to = value.forward_to;
      this.formEdit.business_unit_id = value.business_unit
        ? value.business_unit.id
        : "";
      this.formEdit.business_unit = value.business_unit
        ? value.business_unit
        : "";
      this.modal.edit = true;
    },
  },
};
</script>

//
<style>
.set-max-size {
  min-height: 220px;
  max-height: 250px;
}
</style>
