const state = {
  isLoading: true,
  resetFilter: false,
};
const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  resetFilter(state) {
    return state.resetFilter;
  },
};
const mutations = {
  updateIsLoading: (state, payload) => {
    state.isLoading = payload;
  },
  setResetFilter: (state, payload) => {
    state.resetFilter = payload;
  },
};
const actions = {
  updateIsLoading(context, payload) {
    context.commit("updateIsLoading", payload);
  },
  resetFilter(context, payload) {
    context.commit("setResetFilter", payload);
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
