import { httpClientOmni } from "@/libraries";

export default {
  get() {
    return httpClientOmni.get("/chat-template?per_page=100");
  },
  create(payload) {
    return httpClientOmni.post("chat-template", payload);
  },
  update(payload){
    return httpClientOmni.post(`chat-template/${payload.id}/update`, payload);
  },
  delete(payload) {
    return httpClientOmni.get(`v3/chat-template/${payload.id}/delete`);
  },
};
