import { httpClientAuth } from "@/libraries";
import { getUrl } from "@/utils";
import { transFormer } from "@/utils";

export default {
  async get() {
    const url = getUrl("apps");
    const response = await httpClientAuth.get(url);
    return response.data;
  },
  async createApp(data) {
    const response = await httpClientAuth.post("/create-apps", {
      userId: data.id,
      appName: data.name,
      description: data.description,
    });
    return transFormer.response(response);
  },
  async createBot(data) {
    const response = await httpClientAuth.post("/create-bots", {
      userId: data.id,
      botName: data.name,
      description: data.desc,
      icon: data.icon,
    });
    return response.data;
  },
  async deleteApps(data) {
    const response = await httpClientAuth.post("/delete-apps", {
      checkapp: data,
    });
    return transFormer.response(response);
  },
  async deleteBots(data) {
    const response = await httpClientAuth.post("/delete-bots", {
      checkbot: data,
    });
    return transFormer.response(response);
  },
  async checkUpgrade(data) {
    const response = await httpClientAuth.post("/plan/check", {
      productId: data.productId,
      productType: data.productType,
    });
    return transFormer.response(response);
  },
  async editProject(data) {
    const response = await httpClientAuth.post("/edit-project", {
      appName: data.name,
      description: data.description,
      icon: data.icon,
      id: data.id,
    });
    return transFormer.response(response);
  },
  async v3CreateApp(data) {
    const response = await httpClientAuth.post("/v3/create-apps", {
      userId: data.id,
      appName: data.name,
      icon: data.icon,
      description: data.description,
    });
    return transFormer.response(response);
  },
};
