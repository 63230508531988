<template>
  <div id="appId">
    <div class="loading-wrapper" v-show="isLoading">
      <div class="loading-screen">
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import NotificationHelper from "@/helpers/notification.helper";
export default {
  name: "App",
  data() {
    return {
      title: "Dashboard",
      agent_id: null,
      filter: {
        channel: false,
        business_unit: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/isLoading",
      agentRole: "accessibility/isAgent",
    }),
    apiUrl() {
      return (
        process.env.VUE_APP_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/accessibility`
      );
    },
    url() {
      return this.$route.path.split("/");
    },
    accessibilityUrlV3() {
      return (
        process.env.VUE_APP_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/v3/accessibility`
      );
    },
    assignedChannel() {
      return this.$store.getters["agent/assignedChannel"];
    },
  },
  watch: {
    agentRole(val) {
      if (val) {
        this.agent_id = this.$store.getters.localStorage.decodedUserId;
      }
    },
  },
  async created() {
    await this.$store.dispatch("accessibility/getRoleV3");
    if (
      window.location.pathname.split("/")[1] != "auth" &&
      window.location.pathname.split("/")[1] != "workspace"
    ) {
      this.$store.dispatch("layout/changeLayoutType", {
        layoutType: "twocolumn",
      });
    }
    let Notification = new NotificationHelper({ vm: this });
    // console.log("client connected", this.$echo.connector.socket.connected);
    this.$echo.connector.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    // CHECK SOCKET
    this.$echo.connector.socket.on("connect", () => {
      console.log("socket connected");
      this.$store.commit("agent/SOCKET_STATUS", "Connected");
    });
    this.$echo.connector.socket.on("disconnect", () => {
      console.log("socket disconnected");
      this.$store.commit("agent/SOCKET_STATUS", "Disconnected");
    });
    this.$echo.connector.socket.on("reconnect", () => {
      console.log("socket reconnected");
      this.$store.commit("agent/SOCKET_STATUS", "Connected");
    });

    window.addEventListener("offline", () => {
      console.log("offile");
      this.$store.commit("agent/SOCKET_STATUS", "Disconnected");
    });
    window.addEventListener("online", () => {
      console.log("online");
      this.$store.commit("agent/SOCKET_STATUS", "Connected");
    });

    if (this.$store.getters.appId.decoded !== undefined) {
      await this.$store.dispatch("app/getAppData");
      const app = this.$store.getters["app/app"];
      if (!app.status) {
        this.errorToast("Your Subscription Expired");
      }
    }
    this.$echo
      .channel(`ChannelApp.${this.$store.getters.appId.decoded}`)
      .listen("LivechatRequest", (data) => {
        // console.log("LivechatRequest -> ", data);
        this.$store.dispatch("chat/filterRoom", {
          room: data.room,
          agent: this.agentRole,
        });
        let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

        if (filter) {
          // Notification.show({ data, notificationType: "live-request" });
          // if(this.assignedChannel.includes(data.room.channel.id)){
          this.$store.dispatch("chat/liveChatRequest", data);
          this.$store.dispatch("agent/needUpdateAgent", true);
        }
      })
      .listen("LivechatResolved", (data) => {
        this.$store.dispatch("chat/filterRoom", {
          room: data.room,
          agent: this.agentRole,
        });
        let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

        if (filter) {
          // if(this.assignedChannel.includes(data.room.channel.id)){
          this.$store.dispatch("chat/liveChatResolved", data);
          this.$store.dispatch("agent/needUpdateAgent", true);
        }
        // }
      })
      .listen("LivechatAssigned", (data) => {
        // console.log("LivechatAssigned ->", data);
        this.$store.dispatch("chat/filterRoom", {
          room: data.room,
          agent: this.agentRole,
        });
        let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

        if (filter) {
          this.$store.dispatch("chat/livechatAssigned", data);
          this.$store.dispatch("agent/removeApproveAndWaitingAssignment", data);
          this.$store.dispatch("agent/needUpdateAgent", true);
        }
      })
      .listen("LivechatHandle", (data) => {
        // console.log("LivechatHandle ->", data);
        this.$store.dispatch("chat/filterRoom", {
          room: data.room,
          agent: this.agentRole,
        });
        let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

        if (filter) {
          this.$store.dispatch("chat/livechatHandle", data);
          this.$store.dispatch("agent/needUpdateAgent", true);
        }
      })
      .listen("LivechatCancel", (data) => {
        this.$store.dispatch("chat/filterRoom", {
          room: data.room,
          agent: this.agentRole,
        });
        let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

        if (filter) {
          this.$store.dispatch("chat/livechatCancel", data);
          this.$store.dispatch("agent/needUpdateAgent", true);
        }
      })
      .listen("RejectAssign", (data) => {
        // console.log("RejectAssign", data);
        this.$store.dispatch("agent/rejectAssign", data);
        if (
          this.$store.state.localStorage.decodedUserId ==
          data.room.livechat.handle_by
        ) {
          const message = `${data.agent.user.name} ${data.message}`;
          this.successToast(message);
        }
      })
      .listen("OnlineOffline", ({ current_status, user_id }) => {
        this.$store.dispatch("agent/updateAvailability", {
          current_status,
          user_id,
        });
        let data = { status: current_status, id: user_id };
        this.emitter.emit("updateOnlineAgentDashboard", data);
        if (user_id === this.$store.state.localStorage.decodedUserId) {
          this.$store.dispatch("agent/setOnline", current_status);
        }
      })
      .listen("CancelRequestLiveAgent", ({ total }) => {
        this.$store.dispatch("chat/updateUnservedRoom", total);
      })
      // .listen("RequestLive", (data) => {
      //   console.log("RequestLive", data);
      //   Notification.show({ ...data, notificationType: "live-request" });
      //   this.$store.dispatch("chat/liveChatRequest", data);
      // })
      .listen("ApprovalAssignToAgent", (data) => {
        this.$store.dispatch("agent/approvalAgent", data);
      })
      // .listen("RequestHandled", (data) => {
      //   const { total } = data;
      //   console.log("Request Handled -> ", data);
      //   console.log("Total -> ", total);
      //   this.$store.dispatch("chat/updateUnservedRoom", total);
      //   this.$store.dispatch("chat/requestHandled", data);
      // })
      // .listen("AssigntAgent", (data) => {
      //   const { total } = data;
      //   console.log("Assign Agent => ", data);
      //   this.$store.dispatch("chat/updateUnservedRoom", total);
      //   this.$store.dispatch("chat/assignToAgent", data);
      // })
      // .listen("ChatResolved", (data) => {
      //   console.log("Chat resolved =>", data);
      //   this.$store.dispatch("chat/liveChatResolved", {
      //     livechat: data.livechat,
      //   });
      // })
      .listen("ChangeMessage", (data) => {
        this.$store.dispatch("chat/filterRoom", {
          room: data.room,
          agent: this.agentRole,
        });
        let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);
        if (filter) {
          this.$store.commit("chat/HANDLE_CHANGE_MESSAGE", data);
        }
      })
      .listen("NewMessage", (data) => {
        this.$store.dispatch("chat/filterRoom", {
          room: data.room,
          agent: this.agentRole,
          isn_count: true,
        });
        let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);
        if (filter) {
          if (
            data.message.messageable_type == "user_platform" &&
            data.user.id !== this.$store.state.localStorage.decodedUserId
          ) {
            // Notification.show({ ...data, notificationType: "chat-message" });
            this.$store.dispatch("chat/newMessage", data);
            // if (data.room.livechat !== null) {
            //   if (data.room.livechat.status == "request") {
            //     this.$store.dispatch("chat/liveChatUpdate", data);
            //   }
            // }
          } else if (data.message.messageable_type == "bot") {
            // Notification.show({ ...data, notificationType: "chat-message" });
            this.$store.dispatch("chat/newMessage", data);
            // if (data.room.livechat !== null) {
            //   if (data.room.livechat.status == "request") {
            //     this.$store.dispatch("chat/liveChatUpdate", data);
            //   }
            // }
          } else if (data.message.messageable_type == "user") {
            if (this.isAgent) {
              if (data.room.livechat !== null) {
                if (data.room.livechat.status == "live") {
                  if (
                    this.$store.state.localStorage.decodedUserId ==
                    data.room.livechat.handle_by
                  ) {
                    Notification.show({
                      ...data,
                      notificationType: "chat-message",
                    });
                  }
                }
              }
            } else {
              // Notification.show({
              //   ...data,
              //   notificationType: "chat-message",
              // });
            }
            this.$store.dispatch("chat/newMessage", data);

            // if (data.room.livechat !== null) {
            //   if (data.room.livechat.status == "request") {
            //     this.$store.dispatch("chat/liveChatUpdate", data);
            //   }
            // }
            if (this.$route.name == "omni.conversation.chat") {
              if (this.selectedRoom !== null) {
                if (
                  this.selectedRoom.created_by.id == data.room.created_by.id
                ) {
                  return;
                }
              }
              this.$receiveSound.play();
            }
          }
        }
      })
      .listen("NewComment", (data) => {
        console.log("New Comment", data);
        if (
          data.comment.userable_type == "user_platform" &&
          data.user.id !== this.$store.state.localStorage.decodedUserId
        ) {
          this.$store.dispatch("comment/newComment", data);
        } else if (data.comment.userable_type == "user") {
          this.$store.dispatch("comment/newComment", data);
        }
      })
      .listen("RequestLivecall", (data) => {
        this.$store.dispatch("chat/requestLivecall", data);
      })
      .listen("NewNotifTicket", (data) => {
        if (
          this.$store.getters.localStorage.decodedUserId == data.notif.user_id
        ) {
          this.$store.dispatch("app/newNotif", data.notif);
        }
      });
    this.$echo
      .channel(`TicketApp.${this.$store.getters.appId.decoded}`)
      .listen("NewMessageDiscussion", (data) => {
        if (
          data.message.user_id !==
            this.$store.getters.localStorage.decodedUserId &&
          data.message.ticket_id == this.$route.params.ticketId
        ) {
          this.$store.dispatch("ticket/addSendMessage", data);
          Notification.show({
            ...data,
            notificationType: "chat-message-discussion",
          });
        }
      })
      .listen("NewInquiries", (data) => {
        if (
          data.user !== this.$store.getters.localStorage.decodedUserId &&
          data.message.ticket_id == this.$route.params.ticketId
        ) {
          this.$store.dispatch("mail/addMail", data.message);
        }
      })
      // .listen("UpdateStatusTicket", (data) => {
      //   if (data.ticket == this.$route.params.ticketId) {
      //     this.$store.dispatch("ticket/updateStatusTicket", data);
      //     this.$store.dispatch("ticket/getUserChangeStatus", data);
      //   }
      // })
      .listen("NewTicket", (data) => {
        let user_id = this.$store.getters.localStorage.decodedUserId;

        function filterToAgentId(item) {
          return item.to_agent_id == user_id;
        }
        let filterUser = data.ticket.agent_ticket.filter(filterToAgentId);
        if (this.$store.getters.localStorage.decodedUserId == filterUser) {
          this.$store.dispatch("ticket/addCreatedTicket", data.ticket);
          this.emitter.emit("refreshTable");
        }
      });
    // this.$echo
    // .channel(`NewTicketApp.${this.$store.getters.appId.decoded}`)
    // .listen("NewTicket", data => {
    //   if (data.ticket.user_info.email == this.$store.getters.localStorage.user.email) {
    //     console.log("data", data);
    //     this.$store.dispatch("ticket/addCreatedTicket", data.ticket);
    //       Notification.show({ ...data, notificationType: "new-ticket"
    //     });
    //   }
    // });
    this.emitter.on("flash-success", (message) => {
      this.successToast(message);
    });
    this.emitter.on("flash-error", (message) => {
      console.log("error");
      this.errorToast(message);
    });

    this.$store.dispatch("agent/getState");
  },
  methods: {},
};
</script>
<style>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif !important;
}

.loading-wrapper {
  z-index: 99999;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.loading-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 63px;
  height: 63px;
  background: rgb(9, 43, 121);
  background: linear-gradient(
    328deg,
    rgba(9, 43, 121, 1) 21%,
    rgba(194, 76, 153, 1) 100%
  );
  border-radius: 16px;
}

.loading-item {
  width: 21px;
  height: 21px;
  display: block;
  float: left;
  background: #efeacc;
  animation: animate 2s ease-in infinite;
  opacity: 0.2;
}

.loading-item:nth-child(3) {
  animation-delay: 0.8s;
}

.loading-item:nth-child(2),
.loading-item:nth-child(6) {
  animation-delay: 0.6s;
}

.loading-item:nth-child(1) {
  border-radius: 16px 0px 0px 0px;
}

.loading-item:nth-child(3) {
  border-radius: 0px 16px 0px 0px;
}

.loading-item:nth-child(7) {
  border-radius: 0px 0px 0px 16px;
}
.loading-item:nth-child(9) {
  border-radius: 0px 0px 16px 0px;
}

.loading-item:nth-child(1),
.loading-item:nth-child(5),
.loading-item:nth-child(9) {
  animation-delay: 0.4s;
}

.loading-item:nth-child(4),
.loading-item:nth-child(8) {
  animation-delay: 0.2s;
}

.loading-item:nth-child(7) {
  animation-delay: 0s;
}

@keyframes animate {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
