<template>
  <div class="card-body">
    <h5 class="card-title mb-3">Integration With Telegram</h5>

    <div class="row row-cols-xxl-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
      <div class="col" v-for="(val, key) in integrations" :key="key">
        <div class="card card-body set-max-size">
          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-xs">
                <div class="avatar-title rounded bg-primary fs-14">
                  <i class="ri-telegram-fill fs-20"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              {{ val.integration_data.botName }}
            </div>
            <div class="flex-shrink-0 align-self-start" data-v-42c37e09="">
              <div class="dropdown" data-v-42c37e09="">
                <button
                  class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-v-42c37e09=""
                >
                  <i class="ri-more-2-fill align-bottom" data-v-42c37e09=""></i>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end"
                  data-v-42c37e09=""
                  style=""
                >
                  <a
                    class="dropdown-item"
                    href="#"
                    data-v-42c37e09=""
                    @click="handleDelete(val.id)"
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-end dropdown">
            <div
              class="form-check form-switch form-switch-right form-switch-md"
              style="opacity: 0.6; cursor: not-allowed"
            >
              <Toggle v-model="val.status" @change="updateStatus(val)" />
            </div>
          </div>
          <div class="mt-auto">
            <p class="d-flex card-text text-muted">
              Added on {{ formatDate(val.created_at, "D MMM, Y") }}
            </p>
            <button
              class="d-flex justify-content-center btn btn-primary btn-sm w-100"
              @click="toggleEdit(val)"
            >
              Configuration
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card card-height-100">
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center"
          >
            <i
              class="ri-add-circle-fill pointer"
              style="font-size: 45px"
              @click="toggleCreate"
            ></i>
            <label>Add</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal
    :showModal="modal.create"
    :modalTitle="'Add Telegram Integration'"
    :isLoading="modal.loading"
    :type="`create`"
    @onClose="modal.create = false"
    @onSubmit="create"
  />
  <Modal
    :showModal="modal.edit"
    :form="formEdit"
    :type="`edit`"
    :modalTitle="'Config Telegram Integration'"
    :isLoading="modal.loading"
    @onClose="modal.edit = false"
    @onSubmit="update"
  />
</template>

<script>
import * as ToastHelper from "@/helpers/toast.helper";
import { httpClientOmni } from "@/libraries";
import Modal from "@/views/integration/components/telegram/ModalTelegram.vue";
import Toggle from "@vueform/toggle";
// import eventBus from "@/helpers/EventBus";

export default {
  data() {
    return {
      modal: {
        create: false,
        edit: false,
        delete: false,
        loading: false,
      },
      form: {
        id: "",
        botToken: "",
        botUsername: "",
        botName: "",
        status: false,
        channelName: "telegram",
      },
      integrations: [],
      integration: {
        id: null,
        app_id: null,
        channel_id: null,
      },
      formEdit: {},
      status: false,
    };
  },
  components: {
    Modal,
    Toggle,
  },
  computed: {
    webhookUrl() {
      //   return "https://21988a9f.ngrok.io"+"/app/public/api/"+this.$store.getters.appId.hashed+'/webhook/telegram'
      return (
        process.env.VUE_APP_OMNICHANNEL_URL +
        "/api/" +
        this.$store.getters.appId.hashed +
        "/webhook/telegram"
      );
    },
  },
  created() {
    this.getListIntegration();
  },
  methods: {
    async getListIntegration() {
      let response = await httpClientOmni.get(`integration/telegram/list`);
      response = response.data.data;
      this.integrations = response;
    },
    toggleCreate() {
      this.formEdit = "";
      this.modal.create = true;
    },
    toggleEdit(value) {
      this.integration.id = value.id;
      this.integration.app_id = value.app_id;
      this.integration.channel_id = value.channel_id;
      this.postfixWebhook = value.integration_data.apiService;
      this.formEdit = value.integration_data;
      this.formEdit.id = value.id;
      this.formEdit.webhookUrl = this.webhookUrl;
      this.modal.edit = true;
    },
    async updateStatus(val) {
      try {
        if (val.status == true) {
          this.status = false;
        }

        this.isSetPrimary = true;
        let response = await httpClientOmni.post(
          `integration/telegram/update-status`,
          {
            id: val.id,
          }
        );
        this.successToast(response.data.message);
        this.getListIntegration();
        this.isSetPrimary = false;
      } catch (error) {
        this.isSetPrimary = false;
        this.errorToast(this, error);
      }
    },
    validation(data) {
      if (!data.botToken || !data.botName || !data.botUsername) {
        this.modal.loading = false;
        this.modal.create = false;
        this.modal.edit = false;
        this.errorToast("Please check again, the field must be filled");
        return false;
      } else {
        return true;
      }
    },
    async create(data) {
      this.modal.loading = true;
      var validate = this.validation(data);
      console.log("validate ", data);

      if (validate != false) {
        let formData = {
          ...data,
          status: false,
          webhookUrl: this.webhookUrl,
        };
        try {
          let response = await httpClientOmni.post(
            `integration/telegram/store`,
            formData
          );
          this.form.status = true;
          this.successToast(response.data.message);
          this.emitter.emit("form:reset");
          this.modal.loading = false;
          this.modal.create = false;
          this.getListIntegration();
        } catch (error) {
          this.modal.create = false;
          this.modal.loading = false;
          console.error("error submit : ", error);
          this.errorToast(this, error);
        }
      }
    },
    async update(data) {
      this.modal.loading = true;
      var validate = this.validation(data);

      if (validate != false) {
        let formData = {
          ...data,
          status: false,
          webhookUrl: this.webhookUrl,
        };
        try {
          let response = await httpClientOmni.put(
            `integration/telegram/store/${formData.id}`,
            formData
          );
          this.form.status = true;
          this.successToast(response.data.message);
          this.emitter.emit("form:reset");
          this.modal.loading = false;
          this.modal.edit = false;
          this.getListIntegration();
        } catch (error) {
          this.modal.edit = false;
          this.modal.loading = false;
          console.error("error submit : ", error);
          this.errorToast(this, error);
        }
      }
    },
    async handleDelete(id) {
      this.$swal
        .fire({
          title: "Are you sure, want to delete this integration ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Submit it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let response = await httpClientOmni.delete(
              `integration/delete/${id}`
            );

            this.successToast(response.data.message);
            this.getListIntegration();
          }
        });
    },
    async disconnectChannel() {
      try {
        let data = {
          channelName: this.form.channelName,
          botToken: this.form.botToken,
          status: false,
        };
        await this.$axios.put(
          `api/${this.$store.getters.appId.hashed}/integrate/update`,
          data
        );
        // response = response.data;

        this.emitter.emit("hideIntegrationModal");
        Toast.success("Channel disconected");
        this.form.status = false;
      } catch (error) {
        this.form.status = !this.form.status;

        Toast.error(error);
      }
    },
    async submitForm() {
      await this.$validator.validateAll();

      let response = await this.getBotProfile();
      if (response) {
        try {
          let formData = {
            ...this.form,
            status: true,
            webhookUrl: this.webhookUrl,
          };
          let response = await this.$axios.post(
            `api/${this.$store.getters.appId.hashed}/integrate`,
            formData
          );
          this.form.status = true;

          this.emitter.emit("hideIntegrationModal");
          Toast.success(response.data.message);
        } catch (error) {
          Toast.error(error);
        }
      } else {
        Toast.error("you must fill the form first");
      }
    },
    async fetchData() {
      await this.$store.dispatch(
        "integration/getIntegrationData",
        this.form.channelName
      );
      let data = await this.$store.getters["integration/integrationData"];
      if (data != null) {
        this.form.botToken = data.integration_data.botToken;
        this.form.botUsername = data.integration_data.botUsername;
        this.form.botName = data.integration_data.botName;
        this.form.status = data.status;
      }
    },
  },
  mounted() {
    this.fetchData();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.bg-class {
  background-color: #fafafa;
  min-height: 750px;
  height: auto;
}
.img-prepend {
  position: relative;
  float: right;
  right: 15px;
  bottom: 28px;
  width: 20px;
  height: 20px;
}
.set-max-size {
  min-height: 200px;
  max-height: 200px;
}
.w-100 {
  width: 100%;
}
</style>
