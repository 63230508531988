<template>
  <div id="layout-wrapper">
    <NavBar />
    <div>
      <!-- ========== Left Sidebar Start ========== -->
      <!-- ========== App Menu ========== -->
      <div
        class="app-menu navbar-menu"
        :style="{ 'background-color': subSidebarColor }"
      >
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark" v-if="domain == 'damcorp'">
            <span class="logo-sm">
              <img src="/img/nikel-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="/img/damcorp-logo.png" alt="" height="17" />
            </span>
          </router-link>
          <router-link to="/" class="logo logo-dark" v-else>
            <span class="logo-sm">
              <img src="/img/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="/img/logo-sm.png" alt="" height="17" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-dark" v-if="domain == 'damcorp'">
            <span class="logo-sm">
              <img src="/img/damcorp-logo.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="/img/damcorp-logo.png" alt="" height="17" />
            </span>
          </router-link>
          <router-link to="/" class="logo logo-light" v-else>
            <span class="logo-sm">
              <img src="@/assets/images/logo-light.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="17" />
            </span>
          </router-link>
          <button
            type="button"
            class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div class="container-fluid">
            <div id="two-column-menu">
              <simple-bar
                class="twocolumn-iconview list-unstyled"
                key="simplebar_twocolumn"
                :style="{ 'background-color': sidebarColor }"
              >
                <a a href="javascript:void(0)" @click="toAuth()" class="logo">
                  <img
                    src="/img/damcorp-logo.png"
                    alt="Logo"
                    height="22"
                    v-if="domain == 'damcorp'"
                  />
                  <img
                    src="/img/nikel-logo.png"
                    alt="Logo"
                    height="22"
                    v-else
                  />
                </a>
                <li
                  v-for="(m, i) in modules"
                  :key="`modules_${i}`"
                  :id="`#sidebartooltip_${m.slug}`"
                >
                  <a
                    class="nav-icon"
                    :href="`#sidebar_${m.slug}`"
                    role="button"
                    @click.prevent="updateMenu(m)"
                  >
                    <i :class="m.icon"></i>
                    <b-tooltip
                      :target="`#sidebartooltip_${m.slug}`"
                      title="Tooltip title"
                      triggers="hover"
                    ></b-tooltip>
                  </a>
                  <span class="menu-name">{{ m.name }}</span>
                </li>
                <li class="menu-article">
                  <a
                    class="nav-icon"
                    href="https://lenna-v3.document360.io/docs/user-manual-lenna-platform-v3"
                    role="button"
                    target="_blank"
                    data-v-77647c13=""
                    ><i class="ri-article-line"></i
                    ><b-tooltip
                      target="#sidebartooltip_article"
                      title="Tooltip title"
                      triggers="hover"
                      data-v-77647c13=""
                    ></b-tooltip
                  ></a>
                  <span class="menu-name">Doc</span>
                </li>
              </simple-bar>
            </div>
            <template v-if="layoutType === 'twocolumn'">
              <simple-bar
                class="navbar-nav"
                key="simplebar_twocolumn"
                id="navbar-nav"
              >
                <li class="menu-title">
                  <span data-key="t-menu">Menu</span>
                </li>
                <li
                  class="nav-item"
                  v-for="(m, i) in modules"
                  :key="`modules_${m.slug}_${i}`"
                >
                  <div
                    class="collapse menu-dropdown"
                    v-if="size(m.v3_menus) > 0 && m.type == 'indirect'"
                    :id="`sidebar_${m.slug}`"
                  >
                    <ul class="nav nav-sm nav-sm-menu flex-column">
                      <!-- Menu sidebar -->
                      <li
                        v-for="(menu, i) in m.v3_menus"
                        :key="`menus_${i}`"
                        class="nav-item"
                      >
                        <!-- if menu null -->
                        <router-link
                          v-if="size(m.v3_menus) == 0"
                          :to="{ name: m.slug }"
                          class="collapsed nav-link nav-link-menu"
                          :class="
                            `${url[2]}/${url[3]}` == `${m.route}/${menu.url}`
                              ? 'active'
                              : `${url[2]}.${url[3]}_${m.route}.${menu.url}`
                          "
                          :data-key="`t-${m.slug}.${menu.slug}`"
                        >
                          <i :class="menu.icon"></i>
                          {{ $t(`t-${m.slug}.${menu.slug}`) }}
                        </router-link>
                        <!-- if menu > 0 -->
                        <router-link
                          v-else
                          :to="{ name: `${m.slug}.${menu.slug}` }"
                          class="nav-link nav-link-menu collapsed"
                          role="button"
                          :class="
                            `${url[2]}/${url[3]}` == `${m.route}/${menu.url}`
                              ? 'active'
                              : `${url[2]}.${url[3]}_${m.route}.${menu.url}`
                          "
                          aria-expanded="true"
                          :aria-controls="`sidebar_${m.slug}_${menu.slug}`"
                          :data-key="`t-${m.slug}.${menu.slug}`"
                        >
                          <i :class="menu.icon"></i>
                          {{ $t(`t-${m.slug}.${menu.slug}`) }}
                        </router-link>
                        <!-- sub menu -->
                        <div
                          v-if="size(menu.submenus) > 0"
                          class="menu-dropdown"
                          :id="`sidebar_${m.slug}_${menu.slug}`"
                        >
                          <ul
                            class="nav nav-sm flex-column"
                            style="padding-left: 5px"
                          >
                            <li
                              class="nav-item"
                              v-for="(sm, idxSubMenu) in menu.submenus"
                              :key="`submenu_${idxSubMenu}`"
                            >
                              <router-link
                                @click="closeSidebar"
                                v-if="size(sm.sub_submenus) == 0"
                                class="nav-link"
                                :class="
                                  `${$route.name}` == `${menu.slug}.${sm.slug}`
                                    ? 'active'
                                    : ''
                                "
                                :data-key="`t-${m.slug}.${menu.slug}.${sm.slug}`"
                                :to="{
                                  name: `${m.slug}.${menu.slug}.${sm.slug}`,
                                }"
                              >
                                {{ `${sm.name}` }}
                                <!-- {{ $t(`t-${m.slug}.${menu.slug}.${sm.slug}`) }} -->
                              </router-link>
                              <!-- <a
                                v-if="size(sm.sub_submenus) > 0"
                                href="javascript:void(0)"
                                class="nav-link"
                                role="button"
                                aria-expanded="false"
                                :aria-controls="`sidebar_${menu.slug}_${sm.slug}`"
                                :data-key="`t-${m.slug}.${menu.slug}.${sm.slug}`"
                              >
                                {{ $t(`t-${m.slug}.${menu.slug}.${sm.slug}`) }}
                              </a>
                              <div
                                v-if="size(sm.sub_submenus) > 0"
                                class="menu-dropdown"
                                :id="`sidebar_${menu.slug}_${sm.slug}`"
                              >
                                <ul class="nav nav-sm flex-column">
                                  <li
                                    class="nav-item"
                                    v-for="(ssm, ssmIndex) in sm.sub_submenus"
                                    :key="ssmIndex"
                                  >
                                    <a
                                      href="#"
                                      class="nav-link"
                                      :data-key="`t-${m.slug}.${menu.slug}.${sm.slug}.${ssm.slug}`"
                                    >
                                      {{
                                        $t(
                                          `t-${m.slug}.${menu.slug}.${sm.slug}.${ssm.slug}`
                                        )
                                      }}
                                    </a>
                                  </li>
                                </ul>
                              </div> -->
                            </li>
                            <li
                              :key="`modules_article`"
                              :id="`#sidebartooltip_article`"
                              class="menu-article"
                            >
                              <i class="ri-article-line"></i>
                              <a
                                title="Documentation"
                                class="nav-link menu-link"
                                target="_blank"
                                href="https://lenna-v3.document360.io/docs/user-manual-lenna-platform-v3"
                              >
                                <i
                                  style="margin-left: 20px"
                                  class="ri-dashboard-2-fill"
                                ></i>
                                <span data-key="t-Documentation"
                                  >Documentation</span
                                >
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </simple-bar>
            </template>
          </div>
        </div>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div class="vertical-overlay"></div>
    </div>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div
        class="page-content"
        :style="hideFooter ? 'padding-bottom: 0px' : ''"
      >
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <BaseFooter v-if="!hideFooter" />
    </div>
    <RightBar />
  </div>
</template>
<script>
import router from "@/router";
import { layoutComputed } from "@/store/helpers";
import { SimpleBar } from "simplebar-vue3";
import Hashids from "hashids";
import NavBar from "@/components/nav-bar.vue";
import RightBar from "@/components/right-bar.vue";
import Footer from "@/components/footer.vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import {
  sortBy,
  filter,
  clone,
  find,
  findIndex,
  some,
  size,
  debounce,
} from "lodash";
import { mapGetters } from "vuex";
import Toast from "@/helpers/toast.helper";
/**
 * Vertical layout
 */
export default {
  components: { NavBar, RightBar, BaseFooter: Footer, SimpleBar },
  data() {
    return {
      size,
      debounce,
      isMenuCondensed: false,
      domain: window.location.hostname.split(".")[0],
    };
  },
  computed: {
    ...layoutComputed,
    modules() {
      let module = this.$store.getters["sidebar/modules"];
      return sortBy(module, ["order"]);
    },
    ...mapGetters({
      userRole: "accessibility/userRole",
      access: "accessibility/getAccess",
      hideFooter: "layout/hideFooter",
      loading: "loading/isLoading",
    }),
    accessibilityUrlV3() {
      return (
        process.env.VUE_APP_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/v3/accessibility`
      );
    },
    url() {
      return this.$route.path.split("/");
    },
    sidebarColor() {
      const domain = window.location.hostname.split(".")[0];
      if (domain == "damcorp") {
        return "#1E5A59";
      } else {
        return "#001A38";
      }
    },
    subSidebarColor() {
      const domain = window.location.hostname.split(".")[0];
      if (domain == "damcorp") {
        return "#4b7b7a";
      } else {
        return "#0b2748";
      }
    },
  },
  created() {
    // document.body.removeAttribute("data-layout", "horizontal");
    // document.body.removeAttribute("data-topbar", "dark");
    // document.body.removeAttribute("data-layout-size", "boxed");
    // this.initActiveMenu();
    this.throttle();
  },
  mounted() {
    document.body.removeAttribute("data-layout", "vertical");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    this.initActiveMenu();
    // this.accessibilityChecker();
  },
  // beforeMount() {
  //   this.accessibilityChecker();
  // },
  methods: {
    closeSidebar() {
      this.toggleHamburgerMenu();
      console.log("congrats, your sub menu clicked :)");
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    throttle: debounce(function () {
      this.accessibilityChecker();
    }, 500),
    initActiveMenu() {
      const pathName = window.location.pathname;
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = Array.from(ul.querySelectorAll("a.nav-link"));
        let activeItems = items.filter((x) => x.classList.contains("active"));
        this.removeActivation(activeItems);
        let matchingMenuItem = items.find((x) => {
          return x.getAttribute("href") === pathName;
        });
        if (matchingMenuItem) {
          this.activateParentDropdown(matchingMenuItem);
        } else {
          var id = pathName.replace("/", "");
          if (id) document.body.classList.add("twocolumn-panel");
          this.activateIconSidebarActive(pathName);
        }
      }
    },

    updateMenu(val) {
      // animate hamburger icon to arrow
      console.log("updateMenu", val);
      if (
        document.querySelector(".hamburger-icon") &&
        !document.querySelector(".open")
      ) {
        document.querySelector(".hamburger-icon").classList.toggle("open");
      }
      let haveBot = cookies.get("lenna_bot_id");
      // console.log("have bot", haveBot);
      if (val.type == "direct" || (!haveBot && val.url == "studio")) {
        return this.$router.push({ name: val.slug });
      }
      document.body.classList.remove("twocolumn-panel");
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = Array.from(ul.querySelectorAll(".show"));
        items.forEach((item) => {
          item.classList.remove("show");
        });
      }
      const icons = document.getElementById("two-column-menu");
      if (icons) {
        const activeIcons = Array.from(
          icons.querySelectorAll(".nav-icon.active")
        );
        activeIcons.forEach((item) => {
          item.classList.remove("active");
        });
      }
      document.getElementById(`sidebar_${val.slug}`).classList.add("show");
      this.activateIconSidebarActive("#sidebar_" + val.slug);
    },

    removeActivation(items) {
      items.forEach((item) => {
        if (item.classList.contains("menu-link")) {
          if (!item.classList.contains("active")) {
            item.setAttribute("aria-expanded", false);
          }
          item.nextElementSibling.classList.remove("show");
        }
        if (item.classList.contains("nav-link")) {
          if (item.nextElementSibling) {
            item.nextElementSibling.classList.remove("show");
          }
          item.setAttribute("aria-expanded", false);
        }
        item.classList.remove("active");
      });
    },

    activateIconSidebarActive(id) {
      var menu = document.querySelector(
        "#two-column-menu .simplebar-content-wrapper a[href='" +
          id +
          "'].nav-icon"
      );
      if (menu !== null) {
        menu.classList.add("active");
      }
    },

    activateParentDropdown(item) {
      // navbar-nav menu add active
      item.classList.add("active");
      let parentCollapseDiv = item.closest(".collapse.menu-dropdown");
      if (parentCollapseDiv) {
        // to set aria expand true remaining
        parentCollapseDiv.classList.add("show");
        parentCollapseDiv.parentElement.children[0].classList.add("active");
        parentCollapseDiv.parentElement.children[0].setAttribute(
          "aria-expanded",
          "true"
        );
        if (
          parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
        ) {
          this.activateIconSidebarActive(
            "#" +
              parentCollapseDiv.parentElement
                .closest(".collapse.menu-dropdown")
                .getAttribute("id")
          );
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .classList.add("show");
          if (
            parentCollapseDiv.parentElement.closest(".collapse")
              .previousElementSibling
          )
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.classList.add("active");
          return false;
        }
        this.activateIconSidebarActive(
          "#" + parentCollapseDiv.getAttribute("id")
        );
        return false;
      }
      return false;
    },

    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },

    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },

    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },

    toAuth() {
      // window.location.href = process.env.VUE_APP_AUTH_URL;
      this.$router.push({ name: "auth.workspace" });
    },
    async accessibilityChecker() {
      // await this.$store.dispatch("sidebar/getMenus");
      // await this.$store.dispatch("accessibility/getRole");
      await this.$store.dispatch("accessibility/getRoleV3");
      // if (this.userRole.new_platform == false) {
      //   if (
      //     this.userRole.slug == "admin" ||
      //     this.userRole.slug == "super-admin"
      //   ) {
      //     return this.$router.push({ name: "update-access" });
      //   }
      //   return this.$router.push({ name: "contact-admin" });
      // }
      if (this.$store.getters.localStorage.decodedUserId) {
        await this.$store.dispatch("integration/getIntegrationData");
        this.fetchBotIntegration();
        const response = await this.$axios.get(this.accessibilityUrlV3);
        if (!response.data.success) {
          // console.log("not success");
          this.$router.replace({ name: "notfound" });
          // this.$router.push("/404");
          // this.$store.dispatch("sidebar/updateMenus", null);
          this.$store.dispatch("sidebar/updateModules", null);
          return Toast.error(response.data.message);
        }
        this.$store.dispatch(
          "accessibility/setAccessibility",
          response.data.data
        );
        // this.$store.dispatch("sidebar/updateMenus", this.access.menu);
        this.$store.dispatch("sidebar/updateModules", this.access.module);
        const listApps = some(this.access.apps, {
          id: this.$route.params.appId,
        });
        if (!listApps) {
          // console.log("cannot access project");
          this.$router.push({ name: "forbidden" });
          // this.$store.dispatch("sidebar/updateMenus", null);
          this.$store.dispatch("sidebar/updateModules", null);
          this.errorToast(
            "You need invited to this app before access, contact your admin!"
          );
        }
        const currentModuleUrl = this.url[2];
        const canAccessThisModuleUrl = some(this.access.module, function (o) {
          return o.url == currentModuleUrl;
        });
        console.log("canAccessThisModuleUrl", canAccessThisModuleUrl);
        if (!canAccessThisModuleUrl) {
          if (this.$router.name !== "billing") {
            // return this.$router.push({ name: "forbidden" });
            this.goToNearestModuleMenu(this.access.module);
          }
        } else {
          let currentModuleData = find(this.access.module, function (m) {
            return m.url == currentModuleUrl;
          });
          if (currentModuleData.v3_menus.length > 0) {
            const currentMenuUrl = this.url[3];
            const canAccessThisMenuUrl = some(
              currentModuleData.v3_menus,
              function (o) {
                return o.url == currentMenuUrl;
              }
            );
            if (!canAccessThisMenuUrl) {
              // return this.$router.push({ name: "forbidden" });
              this.goToNearestModuleMenu(this.access.module);
            }
          }
          // this.goToMenuOfCurrentModule(this.access.module);
        }
        await this.$store.dispatch("integration/getIntegrationData", "bot");
      } else {
        // console.log("notfound");
        // this.$router.push({ name: "404" });
        this.$router.push({ name: "login" });
      }
    },
    goToNearestModuleMenu(modules) {
      console.log("goToNearestModuleMenu");
      this.haveAccessToCurrentApp = true;
      const moduleUrl = this.url[2];
      const moduleIdx = findIndex(modules, function (module) {
        return module.url == moduleUrl;
      });
      // console.log("moduleIdx", moduleIdx);
      if (moduleIdx == -1) {
        if (size(modules[0].menus) > 0) {
          return this.moduleHaveMenus(modules, 0);
        }
        return this.goToFirstNearestModule(modules);
      }
      if (size(modules[moduleIdx].menus) > 0) {
        return this.moduleHaveMenus(modules, moduleIdx);
      }
    },
    goToFirstModule(modules) {
      // console.log("goToFirstModule");
      this.$router
        .push({
          name: modules[0].route,
        })
        .catch((err) => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
    },
    moduleHaveMenus(modules, moduleIdx) {
      console.log("moduleHaveMenus");
      const menuUrl = this.url[3];
      const menuIdx = findIndex(modules[moduleIdx].v3_menus, function (menu) {
        return menu.slug == menuUrl;
      });
      if (menuIdx !== -1) {
        this.goToCurrentMenuOfCurrentModule(modules, moduleIdx, menuIdx);
      } else {
        this.goToFirstMenuOfCurrentModule(modules, moduleIdx);
      }
    },
    goToMenuOfCurrentModule(modules) {
      console.log("goToMenuOfCurrentModule", modules);
      const menuUrl = this.url[3];
      const moduleUrl = this.url[2];
      console.log("this.url", this.url);
      this.haveAccessToCurrentApp = true;
      const moduleIdx = findIndex(modules, function (module) {
        return module.url == moduleUrl;
      });
      if (size(modules[moduleIdx].v3_menus) > 0) {
        if (menuUrl !== undefined) {
          const menuIdx = findIndex(
            modules[moduleIdx].v3_menus,
            function (menu) {
              return menu.url == menuUrl;
            }
          );
          console.log("menuIdx", menuIdx);
          if (menuIdx !== -1) {
            return this.goToCurrentMenuOfCurrentModule(
              modules,
              moduleIdx,
              menuIdx
            );
          }
        }
      }
      this.goToFirstMenuOfCurrentModule(modules, moduleIdx);
    },
    // goToSubmenuOfCurrent
    goToCurrentMenuOfCurrentModule(modules, moduleIdx, menuIdx) {
      console.log("goToCurrentMenuOfCurrentModule modul", moduleIdx);
      console.log("goToCurrentMenuOfCurrentModule menu", menuIdx);
      const submenuUrl = this.url[4];
      if (size(modules[moduleIdx].v3_menus[menuIdx].submenus) > 0) {
        const submenuIdx = findIndex(
          modules[moduleIdx].v3_menus[menuIdx].submenus,
          function (s) {
            return s.url == submenuUrl;
          }
        );
        if (submenuIdx !== -1) {
          return this.$router
            .push({
              name: `${modules[moduleIdx].slug}.${modules[moduleIdx].v3_menus[menuIdx].slug}.${modules[moduleIdx].v3_menus[menuIdx].submenus[submenuIdx].slug}`,
            })
            .catch((err) => {
              if (err.name != "NavigationDuplicated") {
                throw err;
              }
            });
        }
      }
      this.$router
        .push({
          name: `${modules[moduleIdx].v3_menus[menuIdx].slug}`,
        })
        .catch((err) => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
    },
    goToFirstMenuOfCurrentModule(modules, moduleIdx) {
      console.log("goToFirstMenuOfCurrentModuleIdx", moduleIdx);
      // console.log("goToFirstMenuOfCurrentModule modules", modules);
      // if (size(modules[moduleIdx].v3_menus) == 0) {
      //   this.$router
      //     .push({
      //       // name: modules[moduleIdx].v3_menus[0].slug
      //       name: this.url[3] ? `${this.url[2]}.${this.url[3]}` : this.url[2],
      //     })
      //     .catch((err) => {
      //       if (err.name != "NavigationDuplicated") {
      //         throw err;
      //       }
      //     });
      // } else if (size(modules[moduleIdx].v3_menus) > 0) {
      //   this.$router
      //     .push({
      //       name: this.url[3] ? `${this.url[2]}.${this.url[3]}` : this.url[2],
      //     })
      //     .catch((err) => {
      //       if (err.name != "NavigationDuplicated") {
      //         throw err;
      //       }
      //     });
      // } else {
      //   this.goToFirstModule(modules);
      // }
    },
    goToFirstNearestModule(modules) {
      if (this.$route.name !== "billing") {
        console.log("goToFirstNearestModule", modules);
        this.$router
          .push({
            name: `${modules[0].slug}`,
          })
          .catch((err) => {
            if (err.name != "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },
    async fetchBotIntegration() {
      const integrations = this.$store.getters["integration/integrations"];
      console.log("integration", integrations);
      const botIntegration = filter(integrations, function (o) {
        return o.name == "bot";
      });
      if (size(botIntegration[0].integration) > 0) {
        const botId = clone(
          botIntegration[0].integration[0].integration_data.botId
        );
        let hashids = new Hashids("", 6);
        var hashedBotId = hashids.encode(botId);
        this.$store.dispatch("bot/getBotList");

        if(!cookies.get('lenna_bot_id')) {
          this.$store.dispatch("bot/setBotId", hashedBotId);
        } else {
          const activeBotId = hashids.decode(cookies.get('lenna_bot_id'));
          if(!this.validateActiveBot(integrations, activeBotId[0])) {
            this.$store.dispatch("bot/setBotId", hashedBotId);
          }
        }
        
      } else {
        cookies.remove("lenna_bot_id");
      }
      // const fullBotStudioUrl = `${this.botStudioUrl}/${hashedBotId}/stories`;
      // this.$store.dispatch("sidebar/alterUrlModule", {
      //   slug: "conversation-studio",
      //   url: fullBotStudioUrl
      // });
    },
    validateActiveBot(channels, activeBotId) {
      let result = false;
      for(const channel of channels) {
        for(const integration of channel.integration) {
          if(integration.bot_id == activeBotId) {
            result = true;
          }
        }
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-menu .navbar-nav .nav-sm .nav-sm .nav-link:before {
  display: none;
}
.menu-name {
  color: #abbae8;
  margin-top: -10px !important;
  display: block;
  font-size: 10px;
}

.menu-article {
  position: absolute !important;
  bottom: 15px;
  left: 15px;
}
</style>
