import { url } from "@/config";
import divide from "lodash/divide";
import truncate from "lodash/truncate";
import now from "lodash/now";
import isString from "lodash/isString";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import replace from "lodash/replace";
import formatter from "@/helpers/formatter.helper";
import { useToast } from "vue-toastification";

const toast = useToast();

const toastOption = {
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

export default {
  computed: {
    isSidebarOpen() {
      return this.$store.state.windowWidth > 500;
    },
    selectedRoom() {
      return this.$store.getters["chat/selectedRoom"];
    },
    isAgent() {
      return this.$store.getters["accessibility/isAgent"];
    },
  },
  methods: {
    consoleLog(data) {
      console.log(data);
    },
    getFileType(file) {
      let type;
      switch (file.type.split("/")[0]) {
        case "image":
          type = "image";
          break;

        default:
          type = "file";
          break;
      }
      return type;
    },
    createUploadFileFormat(file, xhr) {
      return {
        fileName: xhr.data.fileName,
        fileType: this.getFileType(file),
        // originalContentUrl: `${url.upload}/chat/${file.upload.filename}`,
        // previewImageUrl: `${url.upload}/chat/${file.upload.filename}`,
        // fileUrl: `${url.upload}/chat/${file.upload.filename}`,
        originalContentUrl: xhr.data.fileUrl,
        previewImageUrl: xhr.data.fileUrl,
        fileUrl: xhr.data.fileUrl,
        fileSize: divide(file.size, 1000000),
      };
    },
    imgErrorHandler(event) {
      event.target.src = require("@/assets/images/misc/no_avatar.jpg");
    },
    withUrl(path) {
      return (
        process.env.VUE_APP_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/${path}`
      );
    },
    withUrlBot(path) {
      return (
        process.env.VUE_APP_BOTSTUDIO_URL +
        `/api/${this.$store.state.bot.botId}/${path}`
      );
    },
    successToast(message) {
      toast.success(message, toastOption);
    },
    errorToast(message) {
      let err = "Failed";
      if (isString(message)) {
        err = message;
      }
      toast.error(err, toastOption);
    },
    serializer(response) {
      return response.data.data;
    },
    createNotify(type, message) {
      console.log(this.$toast);
      this.$toast.show(message, {
        type,
      });
    },
    createFlash(type, message) {
      if (type == "success") {
        this.$notify({
          group: "notif",
          type: "info",
          title: "Success",
          text: message,
        });
      } else {
        this.$notify({
          group: "error",
          type: "error",
          title: "Error",
          text: message,
        });
      }
    },
    handleToDatabase(type, ...params) {
      return this.$axios[type](...params)
        .then((response) => {
          this.successToast(response.data.message);
          return response;
        })
        .catch((error) => {
          this.errorToast(error);
          return error;
        });
    },
    truncateText(text, length) {
      const data = truncate(text, {
        length: length,
      });
      return data;
    },
    resetObjectValue(o) {
      for (var key in o) {
        // eslint-disable-next-line no-prototype-builtins
        if (!o.hasOwnProperty(key)) continue;
        var val = o[key];
        switch (typeof val) {
          case "string":
            o[key] = "";
            break;
          case "number":
            o[key] = 0;
            break;
          case "boolean":
            o[key] = false;
            break;
          case "object":
            if (val === null) break;
            if (val instanceof Array) {
              o[key] = [];
              break;
            }
            val = {};
            //Or recursively clear the sub-object
            //resetObject(val);
            break;
        }
      }
    },
    acronymText(text) {
      try {
        if (text) {
          var matches = text.match(/\b(\w)/g); // ['J','S','O','N']
          var acronym = matches.join("").toUpperCase();
          return truncate(acronym, {
            length: 3,
          });
        } else {
          return "#";
        }
      } catch (error) {
        console.log("error", error);
        console.log("acronym", text);
        return truncate(text, {
          length: 2,
        });
      }
    },
    formatDate(date, format) {
      if (isNull(date)) {
        // return "sending";
        return formatter.formatDate(now(), format);
      }
      return formatter.formatDate(date, format);
    },
    parseDate(date) {
      return formatter.parseDate(date);
    },
    channelColor(channel) {
      const color = {
        whatsapp: "#004995",
        line: "#1dd1a1",
        webchat: "#ff9c39",
        mobile: "#029aca",
        facebook: "#c8d6e5",
        intagram: "#f368e0",
        ticket: "#3aa509",
        email: "#f03131",
      };

      return color[channel] ? color[channel] : "#405189";
    },
    formatCurrency(value) {
      // const val = toString(value);
      // if (isUndefined(val)) {
      // return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, ",");
      return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      // }
      // return val;
    },
    setAltImg(event) {
      event.target.src = url.appUrl + "/images/pictures/no_avatar.jpg";
    },
  },
};
