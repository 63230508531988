const chatRoute = [
  {
    path: "/:appId/omni",
    name: "omni",
    component: () => import("@/views/omni-messaging/chat/pages/ChatMain.vue"),
    props: true,
    redirect: {
      name: "omni.conversation",
    },
    meta: {
      title: "Omni Messaging",
    },
  },
  {
    path: "/:appId/omni/conversation",
    name: "omni.conversation",
    component: () => import("@/views/omni-messaging/chat/pages/ChatMain.vue"),
    props: true,
    redirect: {
      name: "omni.conversation.chat",
    },
    meta: {
      title: "Conversation",
    },
  },
  {
    path: "/:appId/omni/conversation/chat",
    name: "omni.conversation.chat",
    component: () => import("@/views/omni-messaging/chat/pages/ChatMain.vue"),
    meta: {
      title: "Conversation",
    },
  },
  {
    path: "/:appId/omni/conversation/mail",
    name: "omni.conversation.mail",
    component: () => import("@/views/mail/pages/MailMain.vue"),
    props: true,
    meta: {
      title: "Mailbox",
    },
  },
  {
    path: "/:appId/omni/conversation/comment",
    name: "omni.conversation.comment",
    component: () =>
      import("@/views/omni-messaging/comment/pages/FeedList.vue"),
    props: true,
    meta: {
      title: "Comment",
    },
  },
  {
    path: "/:appId/omni/conversation/comment/:feedId",
    name: "omni.conversation.comment-detail",
    component: () => import("@/views/omni-messaging/comment/pages/Comment.vue"),
    props: true,
    meta: {
      title: "Comment",
    },
  },
  {
    path: "/:appId/omni/conversation/call",
    name: "omni.conversation.call-webpage",
    component: () =>
      import("@/views/omni-messaging/call/components/VideoCallWebpage.vue"),
    props: true,
    meta: {
      title: "Comment",
    },
  },
];
export default chatRoute;
