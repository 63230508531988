import { camelCase } from "lodash";
// Register each file as a corresponding Vuex module. Module nesting
// will mirror [sub-]directory hierarchy and modules are namespaced
// as the camelCase equivalent of their file name.
/**
 * Auth
 */
import workspaceStore from "./auth/workspace.store";

/**
 * OMNI MESSAGING STORES
 */
import AgentStore from "./omni-messaging/agent.store";
import TagStore from "./omni-messaging/tag.store";
import ChatTemplateStore from "./omni-messaging/chat-template.store";
import Segment from "./omni-messaging/segment";
import RoleStore from "./omni-messaging/role.store";
import MenuStore from "./omni-messaging/menu.store";
import WebchatStore from "./omni-messaging/webchat.store";
import ChatStore from "./omni-messaging/chat.store";
import LivechatStore from "./omni-messaging/livechat.store";
import GroupStore from "./omni-messaging/group.store";
import CommentStore from "./omni-messaging/comment.store";
import broadcastByChannel from "./omni-messaging/broadcast-by-channel.store";

/**
 * CONVERSATION STUDIO STORES
 */
import BotStore from "./conversation-studio/bot.store";
import StoryGroupsStore from "./conversation-studio/story-groups.store";
import StoryStore from "./conversation-studio/story.store";
import FunctionListStore from "./conversation-studio/function-list.store";
import Entities from "./conversation-studio/entities";
import VoiceReplace from "./conversation-studio/voice-replace";
import Synonym from "./conversation-studio/synonym";
import GeneralSetting from "./conversation-studio/general-setting.store";
import FallbackInput from "./conversation-studio/fallback-input/fallback-input.store.js";
import StoryEditor from "./conversation-studio/story-editor";
import ReadingLibrary from "./conversation-studio/reading-library.store";

/**
 * DASHBOARD STORES
 */
import DashboardSlaStore from "./dashboard/dashboard.sla.store";
import DashboardStore from "./dashboard/dashboard.store";

/**
 * OTHER STORES
 */
import sidebarStore from "./main/sidebar.store";
import notificationStore from "./main/notification.store";
import appStore from "./main/app.store";
import accessibilityStore from "./main/accessibility.store";
import { loadingStore } from "./main/loading.store";
import integrationStore from "./integration/integration.store";
import filesystemStore from "./main/filesystem.store";

/**
 * TICKET STORES
 */
import TicketStore from "./ticketing/ticket.store";

/**
 * Transaction STORES
 */
import TransactionStore from "./transaction/transaction.store";

/**
 *   Parking
 */
import AreaStore from "./parking/area.store";
import DeviceStore from "./parking/device.store";

/**
 *   Settings
 */
import SettingsStore from "./settings/settings.store";
import FollowUpStore from "./settings/follow-up.store";

/**
 *   Customers
 */
import CustomersStore from "./customers/customers.store";

/**
 *   Email
 */
import MailStore from "./mail/mail.store";

/**
 *   Whatsapp
 * */
import WhatsappTemplate from "./whatsapp/wa-template.store";
import WhatsappBroadcast from "./whatsapp/wa-broadcast.store";

/**
 * BNI STORE
 */
import BNIBlock from "./bni/block.store";

/**
 * REPORT STORE
 */
import ReportStore from "./report/report.store";
import ReportTimeServedStore from "./report/report_time_served.store";

const modulesCache = {};
const storeData = {
  modules: {
    dashboard: DashboardStore,
    sidebar: sidebarStore,
    app: appStore,
    notification: notificationStore,
    accessibility: accessibilityStore,
    tag: TagStore,
    segment: Segment,
    agent: AgentStore,
    chattemplate: ChatTemplateStore,
    role: RoleStore,
    menu: MenuStore,
    webchat: WebchatStore,
    bot: BotStore,
    "story-groups": StoryGroupsStore,
    story: StoryStore,
    "function-list": FunctionListStore,
    entities: Entities,
    "voice-replace": VoiceReplace,
    synonym: Synonym,
    "general-setting": GeneralSetting,
    "fallback-input": FallbackInput,
    "story-editor": StoryEditor,
    ticket: TicketStore,
    transaction: TransactionStore,
    "dashboard-sla": DashboardSlaStore,
    loading: loadingStore,
    integration: integrationStore,
    chat: ChatStore,
    area: AreaStore,
    workspace: workspaceStore,
    device: DeviceStore,
    settings: SettingsStore,
    customers: CustomersStore,
    livechat: LivechatStore,
    mail: MailStore,
    "wa-template": WhatsappTemplate,
    "wa-broadcast": WhatsappBroadcast,
    filesystem: filesystemStore,
    group: GroupStore,
    "reading-library": ReadingLibrary,
    "bni-block": BNIBlock,
    report: ReportStore,
    "report:time_served": ReportTimeServedStore,
    "setting:follow_up": FollowUpStore,
    comment: CommentStore,
    broadcastByChannel,
  },
};

(function updateModules() {
  // Allow us to dynamically require all Vuex module files.
  // https://webpack.js.org/guides/dependency-management/#require-context
  const requireModule = require.context(
    // Search for files in the current directory.
    ".",
    // Search for files in subdirectories.
    true,
    // Include any .js files that are not this file or a unit test.
    /^((?!index|\.unit\.).)*\.js$/
  );

  // For every Vuex module...
  requireModule.keys().forEach((fileName) => {
    const moduleDefinition = requireModule(fileName);

    // Skip the module during hot reload if it refers to the
    // same module definition as the one we have cached.
    if (modulesCache[fileName] === moduleDefinition) return;

    // Update the module cache, for efficient hot reloading.
    modulesCache[fileName] = moduleDefinition;

    // Get the module path as an array.
    const modulePath = fileName
      // Remove the "./" from the beginning.
      .replace(/^\.\//, "")
      // Remove the file extension from the end.
      .replace(/\.\w+$/, "")
      // Split nested modules into an array path.
      .split(/\//)
      // camelCase all module namespaces and names.
      .map(camelCase);

    // Get the modules object for the current path.
    const { modules } = getNamespace(storeData, modulePath);

    // Add the module to our modules object.
    modules[modulePath.pop()] = {
      // Modules are namespaced by default.
      namespaced: true,
      ...moduleDefinition,
    };
  });

  // If the environment supports hot reloading...
  if (module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept(requireModule.id, () => {
      // Update `storeData.modules` with the latest definitions.
      updateModules();
      // Trigger a hot update in the store.
      require("../index").default.hotUpdate({ modules: storeData.modules });
    });
  }
})();

// Recursively get the namespace of a Vuex module, even if nested.
function getNamespace(subtree, path) {
  if (path.length === 1) return subtree;

  const namespace = path.shift();
  subtree.modules[namespace] = {
    modules: {},
    namespaced: true,
    ...subtree.modules[namespace],
  };
  return getNamespace(subtree.modules[namespace], path);
}

export default storeData.modules;
