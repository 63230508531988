import { createStore } from "vuex";

import modules from "./modules";
import Router from "@/router/index";

import Hashids from "hashids";

let hashids = new Hashids("", 6);

const store = createStore({
  state: {
    appUrl: process.env.VUE_APP_OMNICHANNEL_URL,
    localStorage: {
      user: JSON.parse(localStorage.getItem("user")),
      decodedUserId: localStorage.getItem("user")
        ? hashids.decode(JSON.parse(localStorage.getItem("user")).id)[0]
        : null,
    },
    windowWidth: window.innerWidth,
  },
  getters: {
    localStorage(state) {
      return state.localStorage;
    },
    router() {
      return Router.currentRoute;
    },
    appId() {
      return {
        // hashed: getters.router.params.appId,
        // decoded: hashids.decode(getters.router.params.appId)[0],
        hashed: window.location.pathname.split("/")[1],
        decoded: hashids.decode(window.location.pathname.split("/")[1])[0],
      };
    },
  },
  mutations: {
    UPDATE_WIDTH: (state) => {
      state.windowWidth = window.innerWidth;
    },
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
