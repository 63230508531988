// import Vue from "vue";
// import VueCookies from "vue-cookies";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
// Vue.use(VueCookies);
const botId = cookies.get("lenna_bot_id");
// const botId = store.getters["bot/botId"].hashed;
const urlTemp =
  window.location.hostname.split(".")[0] == "damcorp"
    ? process.env.VUE_APP_APP_URL
    : process.env.VUE_APP_DAMCORP_URL;
const appId = window.location.pathname.split("/")[1];
const getEnv = (key) => process.env[key];

export default {
  api: `${getEnv("VUE_APP_OMNICHANNEL_URL")}/api/${appId}`,
  upload: `${getEnv("VUE_APP_OMNICHANNEL_URL")}/upload`,
  botApi: `${getEnv("VUE_APP_BOTSTUDIO_URL")}/api/${botId}`,
  botUrl: getEnv("VUE_APP_BOTSTUDIO_URL"),
  appUrl: getEnv("VUE_APP_OMNICHANNEL_URL"),
  // baseUrl: getEnv("VUE_APP_APP_URL"),
  baseUrl: urlTemp,
  authUrl: `${getEnv("VUE_APP_AUTH_URL")}/api`,
  plainApi: `${getEnv("VUE_APP_OMNICHANNEL_URL")}/api`,
  webchatUrl: `${getEnv("VUE_APP_WEBCHAT_URL")}`,
};
