import moment from "moment";
import size from "lodash/size";
import escape from "lodash/escape";
import deburr from "lodash/deburr";
import split from "lodash/split";
class Formatter {
  currency(value) {
    let val = (value / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  trimText(value, length = 20) {
    if (size(value) > length) {
      let text = value.substr(0, length) + "..";
      return escape(deburr(text));
    }
    return escape(deburr(value));
  }
  trimText50(value, length = 50) {
    if (size(value) > length) {
      let text = value.substr(0, length) + "..";
      return escape(deburr(text));
    }
    return escape(deburr(value));
  }
  formatDate(date, format) {
    return moment(new Date(date)).format(format);
  }
  escape(value) {
    return escape(deburr(value));
  }
  parseDate(date) {
    // let dt = moment(date, "YYYY-MM-DD kk:mm:ss").fromNow();
    let final = "";
    let format = moment(date, "YYYY-MM-DD kk:mm:ss").fromNow();
    let dt = split(format, " ");
    let num = dt[0];
    let name = dt[1];
    switch (name) {
      case "minute":
        name = "m";
        break;
      case "minutes":
        name = "m";
        break;
      case "day":
        name = "d";
        break;
      case "days":
        name = "d";
        break;
      case "month":
        name = "mo";
        break;
      case "months":
        name = "mo";
        break;
      case "year":
        name = "y";
        break;
      case "years":
        name = "y";
        break;
    }
    if (num == "a") {
      num = "1";
    }
    final = num + name;
    if (name == "mo") {
      final = moment(date).format("DD MMM");
    }
    return final;
  }
}
export default new Formatter();
