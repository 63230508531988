import moment from "moment";
import size from "lodash/size";
class Formatter {
  currency(value) {
    let val = (value / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  trimText(value, length = 20) {
    // return _.truncate(value, {
    // 	"length": length,
    // });
    if (size(value) > length) {
      return value.substr(0, length) + "..";
    }
    return value;
  }
  trimText50(value, length = 50) {
    if (size(value) > length) {
      return value.substr(0, length) + "..";
    }
  }
  formatDate(date, format) {
    return moment(date).format(format);
  }
}
export default new Formatter();
