import { WaTemplateService } from "@/services";

const Types = {
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
};

const state = {
  data: [],
  error: null,
  loading: false,
};

const getters = {
  loading: (state) => state.loading,
  error: (state) => state.error,
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_ERROR]: (state, payload) => (state.error = payload),
};

const actions = {
  async getTemplateByIntegrationId({ commit }, integrationId) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await WaTemplateService.getTemplateByIntegrationId(
        integrationId
      );
      return response;
    } catch (error) {
      console.log("error get template by integration id", error);
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async v3GetTemplatePaginate({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await WaTemplateService.v3GetTemplatePaginate(payload);
      return response;
    } catch (error) {
      console.log("error get template paginate", error);
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async deleteTemplate({ commit }, id) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await WaTemplateService.deleteTemplate(id);
      return response;
    } catch (error) {
      console.log("error delete template", error);
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async exportCsv({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await WaTemplateService.exportCsvTemplate(payload);
      return response;
    } catch (error) {
      console.log("error export csv", error);
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async exportPdf({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await WaTemplateService.exportPdfTemplate(payload);
      return response;
    } catch (error) {
      console.log("error export pdf", error);
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async createHsm({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await WaTemplateService.createHsmTemplate(payload);
      return response;
    } catch (error) {
      console.log("error create hsm", error);
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
