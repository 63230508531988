<template>
  <Layout>
    <PageHeader :title="getTitle" :items="items" />
    <div class="row" :class="changeWidthUserWindow">
      <div class="col-lg-2 col-md-3" :class="sidePage ? '' : 'd-none'">
        <div class="">
          <div class="channel-list">
            <template v-for="(m, key) in menu" :key="key">
              <div class="d-flex align-items-center mb-3 mt-3">
                <div class="flex-grow-1">
                  <h5 class="card-title mb-0">{{ changeName(m.category) }}</h5>
                </div>
              </div>
              <div
                class="d-flex mb-1 channel"
                v-for="(submenu, index) in m.submenu"
                :key="index"
                @click="showPage()"
              >
                <div class="flex-shrink-0">
                  <span class="badge badge-soft-secondary p-2">
                    <ImgLazy
                      :src="`/img/new-channels/${submenu.icon}`"
                      error-img="/img/new-channels/integration.png"
                      :alt="submenu.name"
                    />
                  </span>
                </div>
                <div class="flex-grow-1 ms-2 mt-2 overflow-hidden">
                  <!-- <a href="javascript:void(0);"> -->
                  <router-link :to="{ name: submenu.name }">
                    <h6 class="text-truncate fs-14">
                      {{ submenu.subMenuName }}
                    </h6>
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </template>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div
        class="col-lg-10 col-md-9"
        :class="resMobile ? (!sidePage ? '' : 'd-none') : 'lg-show'"
      >
        <div class="card" style="min-height: calc(100vh - 200px)">
          <!-- back icon start -->
          <span class="d-flex flex-row align-items-center">
            <i
              v-if="this.resMobile"
              @click="removePage()"
              class="ri-arrow-left-s-line align-bottom mx-3"
              style="font-size: 2em"
            ></i>
            <p class="ms-2 mt-3" v-if="this.resMobile" @click="removePage()">
              Back
            </p>
          </span>
          <!-- back icon end -->
          <router-view></router-view>
          <!--end card-body-->
        </div>
        <!-- end card -->
      </div>
      <!--end col-->
    </div>
  </Layout>
</template>

<script>
// import SecondSideBar from "@/views/common/components/SecondSidebar";
// import SettingSecondSideBar from "@/views/common/components/SettingSecondSidebar";
// import _ from "lodash";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import ImgLazy from "@/components/base/ImgLazy.vue";

export default {
  components: {
    Layout,
    PageHeader,
    ImgLazy,
    // SecondSideBar
    // SettingSecondSideBar
  },
  computed: {
    changeWidthUserWindow() {
      console.log("width size", this.windowWidth);
      this.onResize();
      return "";
    },
    menu() {
      let menu = _.chain(this.subSubMenu)
        .groupBy("category")
        .map((value, key) => ({ category: key, submenu: value }))
        .value();
      return menu;
    },
    getTitle() {
      return "Integration";
    },
  },
  data() {
    return {
      sidePage: true,
      windowWidth: window.innerWidth,
      resMobile: false,
      items: [
        {
          text: "Channel",
          href: "/",
        },
        {
          text: "Channel Integration",
          active: true,
        },
      ],
      subSubMenu: [
        {
          slug: "whatsapp",
          name: "integration.whatsapp",
          icon: "whatsapp.svg",
          category: "messenger",
          subMenuName: "Whatsapp",
        },
        {
          slug: "integration-api-token",
          name: "integration.apitoken",
          icon: "api.svg",
          category: "apps",
          subMenuName: "Api Token",
        },
      ],
    };
  },
  created() {
    this.onResize();
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    showPage() {
      // this.onResize()
      if (this.windowWidth < 769) {
        console.log("mobile view");
        this.sidePage = false;
      } else {
        console.log("desktop view");
        this.sidePage = true;
      }
    },
    removePage() {
      this.sidePage = true;
    },
    onResize() {
      this.windowWidth = window.innerWidth;

      // if data already clicked
      console.log("data selected");
      // mobile checker
      if (this.windowWidth < 769) {
        console.log("mobile view");
        this.resMobile = true;
      } else {
        console.log("desktop view");
        this.resMobile = false;
        this.sidePage = true;
      }
    },
    isActive(slug) {
      console.log("is active", window.location.pathname.split("/")[4]);
      return window.location.pathname.split("/")[4] == slug;
    },
    changeName(cat) {
      let name = cat;
      switch (cat) {
        case "messenger":
          name = "Messengers";
          break;
        case "social-media":
          name = "Social Media";
          break;
        case "apps":
          name = "Apps";
          break;
        default:
          name = cat;
      }
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge img {
  width: 24px;
}
.channel-list .channel {
  padding: 5px;
  border-radius: 4px;
}
.channel-list .channel:hover {
  background: var(--vz-boxed-body-bg);
}
.channel-list {
  overflow: scroll;
  max-height: calc(100vh - 200px);
}
</style>
