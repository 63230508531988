import IntegrationPage from "@/views/integration/pages/IntegrationPage";

import IntegrationWhatsapp from "@/views/integration/components/IntegrationWhatsapp";
import IntegrationLine from "@/views/integration/components/IntegrationLine";
import IntegrationFacebook from "@/views/integration/components/IntegrationFacebook";
import IntegrationTelegram from "@/views/integration/components/IntegrationTelegram";
import IntegrationMobileOmni from "@/views/integration/components/IntegrationMobileOmni";
import IntegrationMobile from "@/views/integration/components/IntegrationMobile";
import IntegrationChatBot from "@/views/integration/components/IntegrationChatBot";
import IntegrationTwitter from "@/views/integration/components/IntegrationTwitter";
import IntegrationTokopedia from "@/views/integration/components/IntegrationTokopedia";
import IntegrationFreshChat from "@/views/integration/components/IntegrationFreshChat";
import IntegrationHaptik from "@/views/integration/components/IntegrationHaptik";
import IntegrationGoogleBusiness from "@/views/integration/components/IntegrationGoogleBusiness";
// import IntegrationInstagram from "@/views/integration/components/IntegrationInstagram.vue";
// import IntegrationInstagramDetail from "@/views/integration/components/IntegrationInstagramDetail.vue";
import IntegrationEmail from "@/views/integration/components/IntegrationEmail";
import IntegrationApiToken from "@/views/integration/components/IntegrationApiToken";
import Soon from "@/views/integration/components/Soon";
import IntegrationSms from "@/views/integration/components/IntegrationSms";

export default {
  path: "/:appId/integration",
  name: "integration",
  component: () => import("@/views/integration/pages/IntegrationPage.vue"),
  redirect: { name: "integration.whatsapp" },
  props: true,
  meta: {
    title: "Integration",
  },
  children: [
    {
      path: "freshchat",
      name: "integration.freshchat",
      component: IntegrationFreshChat,
    },
    {
      path: "haptik",
      name: "integration.haptik",
      component: IntegrationHaptik,
    },
    {
      path: "google-business",
      name: "integration.google-business",
      component: IntegrationGoogleBusiness,
    },
    {
      path: "sms",
      name: "integration.sms",
      component: IntegrationSms,
    },
    {
      path: "tokopedia",
      name: "integration.tokopedia",
      component: IntegrationTokopedia,
    },
    {
      path: "mobile-omni",
      name: "integration.mobile-omni",
      component: IntegrationMobileOmni,
    },
    {
      path: "line",
      name: "integration.line",
      component: IntegrationLine,
    },
    {
      path: "facebook",
      name: "integration.facebook",
      component: IntegrationFacebook,
    },
    {
      path: "whatsapp",
      name: "integration.whatsapp",
      component: () =>
        import("@/views/integration/components/IntegrationWhatsapp.vue"),
      meta: {
        title: "Whatsapp Integration",
      },
    },
    {
      path: "telegram",
      name: "integration.telegram",
      component: IntegrationTelegram,
    },
    {
      path: "webchat",
      name: "integration.webchat",
      component: () =>
        import("@/views/integration/components/IntegrationWebchat.vue"),
      meta: {
        title: "Webchat Integration",
      },
    },
    {
      path: "webchat/:integrationId",
      name: "integration.webchat.detail",
      component: () =>
        import("@/views/integration/components/IntegrationWebchatDetail.vue"),
      meta: {
        title: "Webchat Integration",
      },
    },
    {
      path: "bot-integration",
      name: "integration.botintegration",
      component: IntegrationChatBot,
    },
    {
      path: "twitter",
      name: "integration.twitter",
      component: IntegrationTwitter,
    },
    {
      path: "instagram",
      name: "integration.instagram",
      component: () =>
        import("@/views/integration/components/IntegrationInstagram.vue"),
      meta: {
        title: "Instagram Integration",
      },
    },
    {
      path: "instagram/:integrationId",
      name: "integration.instagram.detail",
      component: () =>
        import("@/views/integration/components/IntegrationInstagramDetail.vue"),
      meta: {
        title: "Instagram Integration",
      },
    },
    {
      path: "email",
      name: "integration.email",
      component: IntegrationEmail,
      meta: {
        title: "Email Integration",
      },
    },
    {
      path: "soon",
      name: "integration.soon",
      component: Soon,
    },
    {
      path: "apitoken",
      name: "integration.apitoken",
      component: IntegrationApiToken,
    },
    {
      path: "mobile",
      name: "integration.mobile",
      component: IntegrationMobile,
    },
  ],
};
