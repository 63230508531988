import { FilesystemService } from "@/services";

const Types = {
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
};

const state = {
  data: [],
  error: null,
  loading: false,
};

const getters = {
  loading: (state) => state.loading,
  error: (state) => state.error,
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_ERROR]: (state, payload) => (state.error = payload),
};

const actions = {
  async uploadFile({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await FilesystemService.uploadFile(payload);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async uploadFileDiscussion({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await FilesystemService.uploadFileDiscussion(payload);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
  async deleteFile({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await FilesystemService.deleteFile(payload);
      return response;
    } catch (error) {
      commit(Types.SET_ERROR, error);
    }
    commit(Types.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
