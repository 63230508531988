<template>
  <div class="col px-3 py-4">
    <div class="card-header align-items-center d-flex px-0 py-0">
      <div class="flex-grow-1 oveflow-hidden">
        <h4 style="color: black" class="mb-4">Integration API Token</h4>
      </div>
    </div>
    <div class="row">
      <div>
        <div class="form-group">
          <strong class="mb-2">Your Api Token</strong>
          <textarea
            readonly
            spellcheck="false"
            v-model="token"
            class="form-control"
            name=""
            id=""
            cols="30"
            rows="4"
          ></textarea>
        </div>

        <div class="col-md-10 mt-2">
          <base-button @click="submitForm" :loading="loading" size="md">
            Update
          </base-button>
        </div>
      </div>
    </div>
    <!-- <lenna-modal ref="lennaModal"></lenna-modal> -->
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  data() {
    return {
      token: null,
      user: this.$store.getters.localStorage.user,
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.submit(this);
    },
    submit: debounce(async (vm) => {
      try {
        let response = await vm.$axios.get(
          `api/${vm.$store.getters.appId.hashed}/generate-token`
        );
        this.loading = false;
        vm.token = response.data.data;
        return vm.successToast(response.data.message);
      } catch (error) {
        this.loading = false;
        return vm.errorToast(error);
      }
    }, 300),
    async fetchData() {
      let response = await this.$axios.get(
        `api/${this.$store.getters.appId.hashed}/token`
      );
      response = response.data.data;
      this.token = response;
      // console.log('response : ',response )
    },
  },
  async mounted() {
    this.fetchData();
  },
};
</script>
