import * as Types from "./mutation_types";

import { httpClientBot } from "@/libraries";
// // import notification from "@/helpers/EventBus";

export const setLoading = ({ commit }) => commit(Types.SET_LOADING);
export const setError = ({ commit }) => commit(Types.SET_ERROR);

export const receiveFetchStories = ({ commit }, payload) =>
  commit(Types.RECEIVE_FETCH_STORIES_SUCCESS, payload);

export const receiveFetchStoryGroup = ({ commit }, payload) =>
  commit(Types.RECEIVE_FETCH_STORY_GROUP_SUCCESS, payload);

export const fetchStories = ({ dispatch }) => {
  dispatch("setLoading");
  return httpClientBot
    .get("/stories")
    .then((response) => {
      dispatch("receiveFetchStories", response.data);
    })
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const fetchStoryGroup = ({ dispatch }) => {
  dispatch("setLoading");
  return httpClientBot
    .get("/story-groups/card")
    .then((response) => {
      dispatch("receiveFetchStoryGroup", response.data.data);
    })
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const applyFallback = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .put("/fallback-inputs/aplied", payload)
    .then(() => {
      this.emitter.$emit("flash-success", "Success apply fallback");
    })
    .catch(() => dispatch("setError"))
    .finally(() => dispatch("setLoading"));
};

export const newStoryBasedOnFallback = ({ dispatch }, payload) => {
  dispatch("setLoading");
  return httpClientBot
    .post("/stories/create", payload)
    .then(() =>
      this.emitter.emit(
        "flash-success",
        "Success create story based on given fallback"
      )
    )
    .catch(() => {
      dispatch("setError");
      this.emitter.emit(
        "flash-error",
        "Failed create story based on given fallback"
      );
    })
    .finally(() => dispatch("setLoading"));
};

export const deleteFallbackAssignmentRecomendation = (
  { dispatch },
  payload
) => {
  dispatch("setLoading");
  return httpClientBot
    .post("/fallback-input/purge", {
      data: payload,
    })
    .then(() =>
      this.emitter.emit(
        "flash-success",
        "Success remove fallback assignment recomendation"
      )
    )
    .catch(() =>
      this.emitter.emit(
        "flash-error",
        "Failed remove fallback assignment recomendation"
      )
    )
    .finally(() => dispatch("setLoading"));
};
