import * as Types from "./mutation_types";

export default {
  [Types.SET_LOADING]: (state) => (state.isLoading = !state.isLoading),
  [Types.SET_ERROR]: (state) => (state.isError = !state.isError),
  [Types.RECEIVE_FETCH_STORIES_SUCCESS]: (state, payload) =>
    (state.stories = payload),
  [Types.RECEIVE_FETCH_STORY_GROUP_SUCCESS]: (state, payload) =>
    (state.storyGroup = payload),
};
