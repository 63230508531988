<template>
  <div class="card-body">
    <h5 class="card-title mb-3">Integration With Whatsapp Messenger</h5>
    <!-- old style with toggle -->
    <!-- <div class="row g-2">
      <div class="col-sm-4">
        <div class="list-grid-nav hstack gap-1 mb-3">
          <button @click="toggleCreate" class="btn btn-outline-success">
            <i class="ri-add-fill me-1 align-bottom"></i> Add Account
          </button>
        </div>
      </div>
    </div> -->

    <div class="row row-cols-lg-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
      <!-- old style with toggle -->
      <!-- <div class="col" v-for="(val, key) in whatsappAccount" :key="key">
        <div class="card card-body">
          <div
            class="text-end dropdown"
            style="position: absolute; right: 20px"
          >
            <div
              class="form-check form-switch form-switch-right form-switch-md"
            >
              // <input
              //    class="form-check-input code-switcher"
              //    type="checkbox"
              //    id="width-showcode"
              //  />
              <Toggle v-model="val.status" @change="updateStatus(val)" />
            </div>
          </div>

          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-sm">
                <div
                  class="avatar-title rounded bg-soft-success text-success fs-14"
                >
                  {{ acronymText(val.integration_data.whatsappName) }}
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="card-title mb-1">
                {{ val.integration_data.whatsappName }}
              </h5>
              <p class="text-muted mb-0">
                {{ val.integration_data.whatsappNumber }}
              </p>
            </div>
          </div>

          <p class="card-text text-muted">Added on {{ val.created_at }}</p>
          <button class="btn btn-success btn-sm" @click="toggleEdit(val)">
            Configuration
          </button>
        </div>
      </div> -->
      <div class="col" v-for="(val, key) in whatsappAccount" :key="key">
        <div class="card card-body">
          <div
            class="text-end dropdown"
            style="position: absolute; right: 20px"
          >
            <div
              class="form-check form-switch form-switch-right form-switch-md"
            >
              <!-- <edit button /> -->
              <span @click="toggleEdit(val)" class="to-pointer">
                <i class="ri-edit-line text-mute me-2"></i>
              </span>
              <!-- <delete button /> -->
              <span @click="handleDelete(val.id)" class="to-pointer">
                <i class="ri-delete-bin-6-line text-danger"></i>
              </span>
            </div>
          </div>

          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-xs">
                <div class="avatar-title rounded bg-primary fs-14">
                  <i class="ri-whatsapp-fill" style="font-size: 1.6em"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="card-title mb-1">
                {{ val.integration_data.whatsappName }}
              </h5>
              <p class="text-muted mb-0">
                {{ val.integration_data.whatsappNumber }}
              </p>
            </div>
          </div>

          <span class="card-timestamp text-muted">
            Added on {{ val.created_at }}
          </span>
          <span class="card-timestamp text-muted mb-3">
            Last update {{ val.updated_at }}
          </span>
          <base-button
            class="btn btn-soft-secondary btn-sm"
            @click="updateStatus(val)"
            v-if="!val.status"
            >Activate</base-button
          >
          <base-button
            :size="'sm'"
            class="btn btn-soft-danger btn-sm"
            @click="updateStatus(val)"
            v-else
            >Deactivate</base-button
          >
        </div>
      </div>
      <div class="col">
        <div class="card card-height-100">
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center"
          >
            <i
              class="ri-add-circle-fill pointer text-primary"
              style="font-size: 45px"
              @click="toggleCreate"
            ></i>
            <label class="text-primary">Add New Whatsapp</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :showModal="modal.create"
    :form="form"
    :modalTitle="'Add Whatsapp Integration'"
    :isLoading="modal.loading"
    @onClose="modal.create = false"
    @onSubmit="create"
  />
  <Modal
    :showModal="modal.edit"
    :form="formEdit"
    :modalTitle="'Edit Whatsapp Integration'"
    :isLoading="modal.loading"
    @onClose="modal.edit = false"
    @onSubmit="update"
  />
</template>

<script>
// import Toast from "@/helpers/toast.helper";
// // import eventBus from "@/helpers/EventBus";
// import ListWhatsappAccount from "./whatsapp/ListWhatsappAccount";
// import AddWhatsappAccount from "./whatsapp/AddWhatsappAccount";
// import DetailWhatsappAccount from "./whatsapp/DetailWhatsappAccount";
import Modal from "@/views/integration/components/whatsapp/ModalWhatsapp.vue";
import Toggle from "@vueform/toggle";
import { httpClientOmni } from "@/libraries";

export default {
  components: {
    Modal,
    Toggle,
    // "list-whatsapp-account": ListWhatsappAccount,
    // "add-whatsapp-account": AddWhatsappAccount,
    // "detail-whatsapp-account": DetailWhatsappAccount,
  },
  data() {
    return {
      // loading: false,
      modal: {
        create: false,
        edit: false,
        delete: false,
        loading: false,
      },
      formEdit: {},
      form: {
        whatsappName: "",
        whatsappNumber: null,
        apiKey: "",
        apiService: "official",
        scenarioKey: "",
        token: "",
        status: false,
        channelName: "whatsapp",
        whatsappBaseUrl: "",
        namespaceHsm: "",
        username: "",
        password: "",
        webhookUrl: "",
        secretKey: "",
        clientKey: "",
        clientId: "",
        projectId: "",
        bot: null,
        business_unit_id: "",
      },
      integration: {
        id: null,
        app_id: null,
        channel_id: null,
      },
      isShow: "list",
      isPage: "Add Whatsapp Account",
      whatsappAccount: [],
      postfixWebhook: "",
    };
  },
  computed: {
    webhookUrl() {
      return (
        process.env.VUE_APP_OMNICHANNEL_URL +
        "/api/" +
        this.$store.getters.appId.hashed +
        "/webhook/whatsapp-" +
        this.postfixWebhook +
        "/" +
        this.integration.id
      );
    },
    selectedService() {
      return this.form.apiService;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // this.fetchData();
  },
  async created() {
    this.getWhatsappAccount();
    this.$store.dispatch("ticket/getBranchOffice");
    this.$store.dispatch("ticket/getAllGroupManagement", "");
  },
  watch: {
    selectedService: function (val) {
      switch (val) {
        case "infobip":
          this.postfixWebhook = "infobip";
          break;
        case "apiwha":
          this.postfixWebhook = "apiwha";
          break;
        case "damcorp":
          this.postfixWebhook = "damcorp";
          break;
        case "official":
          this.postfixWebhook = "official";
          break;
        case "wappin":
          this.postfixWebhook = "wappin";
          break;
      }
      // console.log(this.postfixWebhook);
    },
  },
  methods: {
    async handleDelete(id) {
      this.$swal
        .fire({
          title: "Are you sure, want to delete this whatsapp integration ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Submit it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let response = await httpClientOmni.delete(
              `integration/delete/${id}`
            );
            this.successToast(response.data.message);
            this.getWhatsappAccount();
          }
        });
    },
    async create(data) {
      // let validated = await this.$validator.validateAll();
      // if (validated) {
      this.postfixWebhook = data.apiService;
      this.modal.loading = true;
      try {
        let formData = {
          ...data,
          status: true,
          webhookUrl: this.webhookUrl,
        };
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/add-whatsapp-account`,
          formData
        );
        this.form.status = true;
        this.successToast(response.data.message);
        // ToastHelper.successToast(this, response.data.message);
        this.emitter.emit("form:reset");
        this.modal.loading = false;
        this.modal.create = false;
        this.getWhatsappAccount();
      } catch (error) {
        this.modal.create = false;
        this.modal.loading = false;
        console.error("error submit : ", error);
        this.errorToast(this, error);
      }
    },
    async updateStatus(wa) {
      // console.log('update status : ', wa);
      try {
        let formData = {
          id: wa.id,
          status: !wa.status,
          updateStatus: true,
        };
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/update-whatsapp-account`,
          formData
        );
        // console.log('response : ', response.data)
        this.successToast(response.data.message);
        this.getWhatsappAccount();
      } catch (error) {
        this.errorToast(this, error);
      }
    },
    async update(data) {
      // let validated = await this.$validator.validateAll();
      // console.log("this.form : ", this.form);
      // console.log("this.webhookUrl : ", this.webhookUrl);
      // if (validated) {
      this.modal.loading = true;
      try {
        let formData = {
          ...data,
          channelName: "whatsapp",
          status: true,
          id: this.integration.id,
          webhookUrl: this.webhookUrl,
        };
        let response = await this.$axios.post(
          `api/${this.$store.getters.appId.hashed}/update-whatsapp-account`,
          formData
        );
        this.form.status = true;
        this.status = true;

        console.log("response : ", response.data);

        this.emitter.emit("form:reset");
        this.successToast(response.data.message);
        this.modal.loading = false;
        this.modal.edit = false;
        this.getWhatsappAccount();
      } catch (error) {
        this.modal.loading = false;
        this.modal.edit = false;
        this.errorToast(error);
      }
      // } else {
      //   ToastHelper.forbiddenToast(this, "you must fill the form first");
      // }
    },
    async getWhatsappAccount() {
      let response = await this.$axios.get(
        `api/${this.$store.getters.appId.hashed}/whatsapp-account`
      );
      response = response.data.data;
      this.whatsappAccount = response;
      // console.log('response : ', this.whatsappAccount);
    },
    toggleCreate() {
      this.modal.create = true;
    },
    toggleEdit(value) {
      this.integration.id = value.id;
      this.integration.app_id = value.app_id;
      this.integration.channel_id = value.channel_id;
      this.postfixWebhook = value.integration_data.apiService;
      this.formEdit = value.integration_data;
      this.formEdit.webhookUrl = this.webhookUrl;
      this.formEdit.integration_id = value.id;
      this.formEdit.status = value.status;
      this.formEdit.bot = value.bot_id;
      this.formEdit.business_unit_id = value.business_unit
        ? value.business_unit.id
        : "";
      this.formEdit.business_unit = value.business_unit
        ? value.business_unit
        : "";
      this.formEdit.group_id = value.group ? value.group.id : "";
      this.formEdit.group = value.group ? value.group : "";
      this.modal.edit = true;
    },
  },
};
</script>
