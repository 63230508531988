export const getUrl = (endpoint) => {
  const hashedUser = JSON.parse(localStorage.getItem("user")).id;
  return `${endpoint}/${hashedUser}`;
};
export const getUniqueArray = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
export const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};
