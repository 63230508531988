import ContactAdmin from "@/views/common/components/ContactAdmin";
// import UpdateAccess from "@/views/common/pages/accessibility/UpdateAccess";

import broadcastRoute from "@/router/omni-messaging/broadcast.route";
import whatsappRoute from "@/router/omni-messaging/whatsapp.route";

import chatRoute from "@/router/omni-messaging/chat.route";
import customerRoute from "@/router/omni-messaging/customer.route";
// import omniReportingRoute from "@/router/omni-messaging/omni-report.route";
// import settingroute from "@/router/omni-messaging/setting.route";
import reportingRoute from "@/router/reports/report.route";

import dashboardRoute from "@/router/dashboard/dashboard.route";
import settingsRoute from "@/router/settings/settings.route.js";

import integrationroute from "@/router/integration/integration.route";

// import salesroute from "@/modules/Sales/routes/sales.route";
// import claimroute from "@/modules/ClaimTripa/routes/claimTripa.route";

import ticketRoute from "@/router/ticketing/ticket.route.js";
import transactionRoute from "@/router/transaction/transaction.route.js";

import conversationStudioRoute from "@/router/conversation-studio/conversation-studio.route";

// auth
import authRoute from "@/router/auth/auth.route";

// parking
import parkingRoute from "@/router/parking/parking.route";

// mail
import mailRoute from "@/router/mail/mail.route.js";

// billing
import billingRoute from "@/router/billing/billing.route";

const errorRoutes = [
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/404",
    name: "notfound",
    component: () => import("@/views/common/components/NotFound.vue"),
    meta: {
      title: "Not Found - 404",
    },
  },
  {
    path: "/403",
    name: "forbidden",
    component: () => import("@/views/common/components/Forbidden.vue"),
    meta: {
      title: "Forbidden - 403",
    },
  },
];

export default [
  // {
  //   path: "/:appId/update-access",
  //   name: "update-access",
  //   component: UpdateAccess,
  // },
  {
    path: "/contact-admin",
    component: ContactAdmin,
    name: "contact-admin",
  },
  dashboardRoute,
  ...settingsRoute,
  ...errorRoutes,
  ...chatRoute,
  customerRoute,
  // omniReportingRoute,
  ...authRoute,
  // // salesroute,
  ...ticketRoute,
  ...transactionRoute,
  ...parkingRoute,
  integrationroute,
  // settingroute,
  broadcastRoute,
  ...reportingRoute,
  ...whatsappRoute,
  // // claimroute,
  conversationStudioRoute,
  ...parkingRoute,
  ...mailRoute,
  ...billingRoute,
];
