<template>
  <div class="card-body">
    <h5 class="card-title mb-3">Integrate With Twitter Direct Message</h5>
    <!-- old style with toggle -->
    <!-- <div class="row g-2">
      <div class="col-sm-4">
        <div class="list-grid-nav hstack gap-1 mb-3">
          <button @click="toggleCreate" class="btn btn-outline-success">
            <i class="ri-add-fill me-1 align-bottom"></i> Add Account
          </button>
        </div>
      </div>
    </div> -->
    <div class="row row-cols-lg-4 row-cols-lg-3 row-cols-md-2 row-cols-1">
      <!-- old style with toggle -->
      <!-- <div class="col" v-for="(i, key) in list" :key="key">
        <div class="card card-body">
          <div
            class="text-end dropdown"
            style="position: absolute; right: 20px"
          >
            <div
              class="form-check form-switch form-switch-right form-switch-md"
            >
              <Toggle v-model="i.status" @change="updateStatus(i)" />
            </div>
          </div>

          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-sm">
                <div
                  class="avatar-title rounded bg-soft-success text-success fs-14"
                >
                  {{ acronymText(i.integration_data.environtment) }}
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="card-title mb-1">
                {{ truncateText(i.integration_data.environtment, "11") }}
              </h5>
            </div>
          </div>

          <p class="card-text text-muted">
            Added on {{ formatDate(i.created_at, "D MMM, Y LT") }}
          </p>
          <button class="btn btn-success btn-sm" @click="toggleEdit(i)">
            Configuration
          </button>
        </div>
      </div> -->

      <div class="col" v-for="(i, key) in list" :key="key">
        <div class="card card-body">
          <div
            class="text-end dropdown"
            style="position: absolute; right: 20px"
          >
            <div
              class="form-check form-switch form-switch-right form-switch-md"
            >
              <!-- <edit button /> -->
              <span @click="toggleEdit(i)" class="to-pointer">
                <i class="ri-edit-line text-mute me-2"></i>
              </span>
              <!-- <delete button /> -->
              <span @click="handleDelete(i.id)" class="to-pointer">
                <i class="ri-delete-bin-6-line text-danger"></i>
              </span>
            </div>
          </div>

          <div class="d-flex mb-4 align-items-center">
            <div class="flex-shrink-0">
              <div class="avatar-xs">
                <div class="avatar-title rounded bg-primary fs-14">
                  <i class="ri-twitter-fill" style="font-size: 1.6em"></i>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 ms-2">
              <h5 class="card-title mb-1">
                {{ truncateText(i.integration_data.environtment, "11") }}
              </h5>
            </div>
          </div>

          <span class="card-timestamp text-muted">
            Added on {{ formatDate(i.created_at, "D MMM, Y LT") }}
          </span>
          <span class="card-timestamp text-muted mb-3">
            Last update {{ formatDate(i.updated_at, "D MMM, Y LT") }}
          </span>
          <base-button
            class="btn btn-soft-secondary btn-sm"
            @click="updateStatus(i)"
            v-if="!i.status"
            >Activate</base-button
          >
          <base-button
            :size="'sm'"
            class="btn btn-soft-danger btn-sm"
            @click="updateStatus(i)"
            v-else
            >Deactivate</base-button
          >
        </div>
      </div>
      <div class="col">
        <div class="card card-height-100">
          <div
            class="card-body d-flex flex-column justify-content-center align-items-center"
          >
            <i
              class="ri-add-circle-fill pointer text-primary"
              style="font-size: 45px"
              @click="toggleCreate"
            ></i>
            <label class="text-primary">Add New Twitter</label>
          </div>
        </div>
      </div>

    </div>
    <config-twitter-integration-modal
      :show-modal="modal"
      :mode="mode"
      :key="key"
      :default-data="form"
      @hide="toggleHide"
    >
    </config-twitter-integration-modal>
  </div>
</template>

<script>
import ConfigTwitterIntegrationModal from "./twitter/ConfigTwitterIntegrationModal.vue";
import moment from "moment";
import Toggle from "@vueform/toggle";
import { debounce, cloneDeep, orderBy } from "lodash";
import { httpClientOmni } from "@/libraries";
export default {
  components: {
    ConfigTwitterIntegrationModal,
    Toggle,
  },
  data() {
    return {
      cloneDeep,
      debounce,
      form: {
        apiKey: "",
        apiKeySecret: "",
        bearerToken: "",
        accessToken: "",
        accessTokenSecret: "",
        environtment: "",
        channelName: "twitter",
        status: false,
        webhookId: null,
        integration: null,
      },
      loading: false,
      modal: false,
      mode: "create",
      key: moment().format("X"),
      list: [],
    };
  },
  computed: {
    webhookUrl() {
      return (
        process.env.VUE_APP_OMNICHANNEL_URL +
        "/api/" +
        this.$store.getters.appId.hashed +
        "/webhook/twitter/{integrationId}"
      );
      // return (
      //   "https://staging.lenna.ai/app/public" +
      //   "/api/" +
      //   this.$store.getters.appId.hashed +
      //   "/webhook/twitter/{integrationId}"
      // );
    },
    integrations() {
      return this.$store.getters["integration/integrations"];
    },
    twitterIntegrations() {
      if (this.integrations.length > 0) {
        return this.integrations.find(function (item) {
          return item.name === "twitter";
        }).integration;
      }
      return [];
    },
  },
  watch: {
    twitterIntegrations: {
      handler(newVal) {
        this.list = orderBy(cloneDeep(newVal), ["id"], ["desc"]);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.list = orderBy(cloneDeep(this.twitterIntegrations), ["id"], ["desc"]);
  },
  methods: {
    async handleDelete(id) {
      this.$swal
        .fire({
          title: "Are you sure, want to delete this integration ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Submit it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let response = await httpClientOmni.delete(
              `integration/delete/${id}`
            );
            this.successToast(response.data.message);
            this.integrations();
          }
        });
    },
    toggleHide() {
      this.modal = false;
      this.fetchData(this);
      // this.key = moment().format("X");
    },

    toggleEdit(val) {
      this.setDefaultData(val);
      this.mode = "update";
      this.modal = true;
    },
    toggleCreate() {
      this.mode = "create";
      this.modal = true;
    },
    setDefaultData(val) {
      this.form.apiKey = val.integration_data.apiKey;
      this.form.apiKeySecret = val.integration_data.apiKeySecret;
      this.form.bearerToken = val.integration_data.bearerToken;
      this.form.accessToken = val.integration_data.accessToken;
      this.form.accessTokenSecret = val.integration_data.accessTokenSecret;
      this.form.environtment = val.integration_data.environtment;
      this.form.channelName = "twitter";
      this.form.status = val.status;
      this.form.webhookId = val.integration_data.webhookId;
      this.form.integration = val;
      this.form.webhookUrl = this.webhookUrl.replace(
        "{integrationId}",
        this.form.integration.id
      );
    },
    updateStatus(val) {
      this.setDefaultData(val);
      this.updateIntegration();
    },
    resetForm() {
      this.resetObjectValue(this.form);
      this.form.integration = null;
      this.form.channelName = "twitter";
      this.form.status = false;
    },
    validateForm() {
      if (this.form.apiKey === "") {
        return {
          success: false,
          message: "Consumer API Key must be filled",
        };
      }
      if (this.form.apiKeySecret === "") {
        return {
          success: false,
          message: "Consumer API Secret must be filled",
        };
      }
      if (this.form.bearerToken === "") {
        return {
          success: false,
          message: "Bearer Token must be filled",
        };
      }
      if (this.form.accessToken === "") {
        return {
          success: false,
          message: "Access Token be filled",
        };
      }
      if (this.form.accessTokenSecret === "") {
        return {
          success: false,
          message: "Secret Token be filled",
        };
      }
      return {
        success: true,
      };
    },
    async updateIntegration() {
      const valid = this.validateForm();
      if (!valid.success) {
        this.errorToast(valid.message);
        return;
      }
      try {
        const { data: response } = await this.$store.dispatch(
          "integration/updateIntegration",
          {
            ...this.form,
          }
        );
        if (response.success) {
          this.successToast(response.message);
          if (this.form.status) {
            await this.setWebhookTwitter();
          } else {
            await this.deleteWebhookTwitter();
          }
        }
        this.$store.dispatch("integration/getIntegrationData", "twitter");
      } catch (error) {
        this.errorToast("Failed to update integration twitter dm");
        this.resetForm();
      }
    },
    async setWebhookTwitter() {
      this.form.webhookUrl = this.webhookUrl.replace(
        "{integrationId}",
        this.form.integration.id
      );
      try {
        const response = await this.$store.dispatch(
          `integration/setV3TwitterWebhookUrl`,
          {
            webhookUrl: this.form.webhookUrl,
            id: this.form.integration.id,
            environtment: this.form.environtment,
          }
        );
        if (response.data.success) {
          this.successToast(response.data.message);
        }
      } catch (error) {
        const err = error ? error.response.data.message : error;
        this.resetForm();
        this.errorToast(`Twitter: ${err}`);
        console.error("error set webhook twitter", error);
      }
    },
    async deleteWebhookTwitter() {
      try {
        await this.$store.dispatch(`integration/deleteV3TwitterWebhookUrl`, {
          webhookId: this.form.webhookId,
          id: this.form.integration.id,
          environtment: this.form.environtment,
        });
        this.successToast("Twitter: Success unsubscribe webhook");
        this.resetForm();
      } catch (error) {
        const err = error ? error.response.data.message : error;
        console.error("error :", err);
        this.errorToast(`Twitter: ${err}`);
        this.resetForm();
      }
    },
    fetchData: debounce(async (vm) => {
      await vm.$store.dispatch("integration/getIntegrationData", "twitter");
      let integrations = await vm.$store.getters["integration/integrations"];
      vm.integrations = integrations.find(function (item) {
        return item.name === "twitter";
      }).integration;
    }, 250),
    handleCopy() {
      let copyCode = document.querySelector("#webhook-url");
      copyCode.setAttribute("type", "text");
      copyCode.select();
      document.execCommand("copy");
    },
  },
};
</script>
<!-- <style src="@vueform/toggle/themes/default.css"></style> -->
<style lang="scss" scoped>
// .bg-class {
//   position: relative;
//   float: right;
//   background-color: #fafafa;
//   min-width: 1000px;
//   min-height: 750px;
//   height: auto;
// }
// .img-prepend {
//   position: relative;
//   float: right;
//   right: 15px;
//   bottom: 28px;
//   width: 20px;
//   height: 20px;
// }
</style>
