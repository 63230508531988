import moment from "moment";
export default (url, overideConfig = {}) => ({
  url,
  resizeWidth: 650,
  thumbnailHeight: 350,
  thumbnailWidth: null,
  thumbnailMethod: "contain",
  addRemoveLinks: true,
  maxFilesize: 5,
  clickable: true,
  headers: {
    userId: JSON.parse(localStorage.getItem("user")).id,
    token: JSON.parse(localStorage.getItem("user")).token.access_token,
  },
  renameFile: function (file) {
    return moment().format("x") + "-" + file.name;
  },
  ...overideConfig,
});
