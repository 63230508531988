import { httpClientAuth } from "@/libraries";
import { transFormer } from "@/utils";

export default {
  async recordAuditAccessLogs(data) {
    const response = await httpClientAuth.post("/audit", {
      ...data,
    });
    return transFormer.response(response);
  },
  async isAuditor() {
    const response = await httpClientAuth.get("/auditor");
    return transFormer.response(response);
  },
  async getAuditLocation(ip) {
    const response = await httpClientAuth.get(`/audit/${ip}`);
    return transFormer.response(response);
  },
};
