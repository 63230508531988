import axios from "axios";
import { axiosOmniConfig } from "@/config";
import { isObject } from "lodash";
let urlTemp =
  window.location.hostname.split(".")[0] == "damcorp"
    ? process.env.VUE_APP_DAMCORP_URL
    : process.env.VUE_APP_APP_URL;
const httpClientOmni = axios.create(axiosOmniConfig);

const requestHandler = (r) => {
  return r;
};

const responseHandler = (r) => {
  return r;
};

const errorHandler = (e) => {
  if (isObject(e)) {
    if (e.response.status === 401) {
      localStorage.removeItem("user");
      // window.location.href = process.env.VUE_APP_APP_URL;
      console.log("urltemp", urlTemp);
      console.log("urlsplit", window.location.hostname.split(".")[0]);
      window.location.href = urlTemp;
    }
  }
  return Promise.reject(e);
};

httpClientOmni.interceptors.request.use(
  (r) => requestHandler(r),
  (e) => errorHandler(e)
);

httpClientOmni.interceptors.response.use(
  (r) => responseHandler(r),
  (e) => errorHandler(e)
);

export default httpClientOmni;
