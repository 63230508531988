<script>
import { layoutComputed } from "@/store/helpers";

import Vertical from "./vertical.vue";
import Horizontal from "./horizontal.vue";
import TwoColumns from "./twocolumn.vue";

export default {
  components: {
    Vertical,
    Horizontal,
    TwoColumns,
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
    isLoading() {
      if (this.$route.path.split("/")[2] != "dashboard") {
        return this.$store.getters["loading/isLoading"];
      } else {
        return false;
      }
    },
    assignedChannel() {
      return this.$store.getters["agent/assignedChannel"];
    },
  },
  beforeCreate() {
    this.$store.dispatch("agent/assignedChannel");
    // this.$store.dispatch("agent/updateOnline", {
    //   state: "online",
    //   // online: true,
    // });
  },
  created() {
    window.onresize = () => {
      this.$store.commit("UPDATE_WIDTH");
    };
  },
};
</script>

<template>
  <div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>

    <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
      <slot />
    </TwoColumns>
  </div>
</template>
