<template>
  <div id="customer-section" class="border-bottom py-2">
    <div class="dropdown-header px-0 d-flex">
      <h6 class="text-overflow text-muted mb-1 text-uppercase flex-grow-1">
        Customers
      </h6>
      <a
        :href="
          $router.resolve({
            name: 'omni.customers.list',
            params: {
              appId: $store.getters.appId.hashed,
            },
            query: {
              filter: this.searchText,
            },
          }).href
        "
        class="text-secondary flex-shrink-0"
      >
        View all
      </a>
      <!-- <router-link
        :to="{
          name: 'omni.customers.list.params',
          params: { filter: this.searchText },
        }"
        class="text-secondary flex-shrink-0"
      >
        View all
      </router-link> -->
    </div>
    <div class="text-center p-4" v-if="isLoading">
      <b-spinner small label="Spinning" variant="primary"></b-spinner>
    </div>
    <div class="max-sec" v-else>
      <template v-if="customers.length > 0">
        <ul class="list-unstyled vstack gap-2 mb-0">
          <li v-for="(row, i) in customers" :key="i">
            <a
              :href="
                $router.resolve({
                  name: 'omni.customers.panel',
                  params: {
                    customer_id: row.hashed_id,
                    appId: $store.getters.appId.hashed,
                  },
                }).href
              "
              class="dropdown-item notify-item"
              style="display: block"
            >
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-xxs">
                  <i class="ri-user-3-line text-secondary"></i>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">{{ handleTextOver(row.name) }}</h6>
                  <small class="text-muted" v-if="row.phone">
                    <!-- <i class="ri-phone-line text-success me-1"></i> -->
                    {{ row.phone ? row.phone : "-" }}
                    &nbsp;|
                  </small>

                  <small class="text-muted">
                    <!-- <i class="ri-mail-line text-danger me-1"></i> -->
                    {{ handleTextOver(row.email) }}
                  </small>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="alert alert-light rounded-pill text-center" role="alert">
          <strong>No data available</strong>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  setup() {},
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      customers: "app/customers",
      searchText: "app/searchText",
      isLoading: "app/searchLoading",
    }),
  },
  methods: {
    handleTextOver(value) {
      if (value.length > 50) {
        return value.substr(0, 50) + "...";
      } else {
        return value;
      }
    },
  },
  created() {},
};
</script>
<style lang="scss">
.max-sec {
  max-height: 10rem;
  overflow-y: auto;
  overflow-x: auto;
}
</style>
