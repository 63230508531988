import { RoleService } from "@/services";
import size from "lodash/size";
import sortBy from "lodash/sortBy";

const Types = {
  SET_DETAIL: "SET_DETAIL",
  SET_LOADING: "SET_LOADING",
  ERROR_SERVICE: "ERROR_SERVICE",
  SET_MODULES: "SET_MODULES",
  SET_ROLE_MENU: "SET_ROLE_MENU",
  SET_ROLE: "SET_ROLE",
  SET_DISABLED_MODULES: "SET_DISABLED_MODULES",
};

const state = {
  detail: [],
  loading: false,
  error: null,
  modules: [],
  disabledModules: [],
  roleMenu: [],
  role: [],
};

const getters = {
  getDetailRole(state) {
    return state.detail;
  },
  getRoleMenu(state) {
    return state.roleMenu;
  },
  modules(state) {
    return state.modules;
  },
  loading(state) {
    return state.loading;
  },
  getRole(state) {
    return state.role;
  },
  disabledModules(state) {
    return state.disabledModules;
  },
};

const mutations = {
  [Types.SET_LOADING]: (state, payload) => (state.loading = payload),
  [Types.SET_DETAIL]: (state, payload) => {
    state.detail = payload;
    let menu = state.modules;
    menu.forEach((e) => {
      payload.menu.forEach((m) => {
        if (e.id == m.id) {
          e.enable = true;
        }
        if (e.submenus.length > 0) {
          e.submenus.forEach((o) => {
            if (m.submenus.length > 0) {
              m.submenus.forEach((s) => {
                if (s.id == o.id) {
                  o.enable = true;
                }
              });
            }
          });
        }
      });
    });
    state.modules = menu;
  },
  [Types.SET_MODULES](state, payload) {
    payload.map(function (module) {
      module.id = `module-${module.id}`;
      module.enable = false;
      module.label = module.name;
      module.children = module.v3_menus;
      if (size(module.v3_menus) > 0) {
        module.v3_menus.map(function (menu) {
          menu.id = `menu-${menu.id}`;
          menu.enable = false;
          menu.label = menu.name;
          menu.children = menu.submenus;
          if (size(menu.submenus) > 0) {
            menu.submenus.map(function (submenu) {
              submenu.id = `submenu-${submenu.id}`;
              submenu.enable = false;
              submenu.label = submenu.name;
              submenu.children = submenu.sub_submenus;
              if (size(submenu.sub_submenus) > 0) {
                submenu.sub_submenus.map(function (sub_submenu) {
                  sub_submenu.id = `sub_submenu-${sub_submenu.id}`;
                  sub_submenu.enable = false;
                  sub_submenu.label = sub_submenu.name;
                });
              }
            });
          }
        });
      }
    });
    state.modules = payload;
  },
  [Types.SET_DISABLED_MODULES](state, payload) {
    payload.map(function (module) {
      module.id = `module-${module.id}`;
      module.label = module.name;
      module.children = module.v3_menus;
      module.isDisabled = true;
      if (size(module.v3_menus) > 0) {
        module.v3_menus.map(function (menu) {
          menu.id = `menu-${menu.id}`;
          menu.label = menu.name;
          menu.children = menu.submenus;
          menu.isDisabled = true;
          if (size(menu.submenus) > 0) {
            menu.submenus.map(function (submenu) {
              submenu.id = `submenu-${submenu.id}`;
              submenu.label = submenu.name;
              submenu.children = submenu.sub_submenus;
              submenu.isDisabled = true;
              if (size(submenu.sub_submenus) > 0) {
                submenu.sub_submenus.map(function (sub_submenu) {
                  sub_submenu.id = `sub_submenu-${sub_submenu.id}`;
                  sub_submenu.label = sub_submenu.name;
                  sub_submenu.isDisabled = true;
                });
              }
            });
          }
        });
      }
    });
    state.disabledModules = payload;
  },
  [Types.SET_ROLE_MENU]: (state, payload) => (state.roleMenu = payload),
  [Types.SET_ROLE]: (state, payload) => (state.role = payload),
};

const actions = {
  async getDetailRole({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      const response = await RoleService.getDetail(payload);
      if (response.data.success) {
        let menu = sortBy(response.data.data.menu, ["id"]);
        menu.forEach(function (element) {
          element.enable = true;
          if (element.submenus.length > 0) {
            element.submenus.forEach(function (e) {
              e.enable = true;
              if (e.slug == "role-management") {
                e.restricted = true;
              } else {
                e.restricted = false;
              }
            });
          }
        });
        commit(Types.SET_DETAIL, {
          menu: menu,
          role: response.data.data.role,
        });
      }
    } catch (err) {
      commit(Types.ERROR_SERVICE, err);
    }
    commit(Types.SET_LOADING, false);
  },
  setModuleData({ commit }, payload) {
    commit(Types.SET_MODULES, payload);
  },
  async getModules({ commit }) {
    try {
      commit(Types.SET_LOADING, true);
      const response = await RoleService.v3GetModules();
      commit(Types.SET_MODULES, response.data.data);
      commit(Types.SET_LOADING, false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("error getModules", err);
      commit(Types.ERROR_SERVICE, err);
      commit(Types.SET_LOADING, false);
    }
  },
  async getDisabledModules({ commit }) {
    try {
      commit(Types.SET_LOADING, true);
      const response = await RoleService.v3GetModules();
      commit(Types.SET_DISABLED_MODULES, response.data.data);
      commit(Types.SET_LOADING, false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("error getDisabledModules", err);
      commit(Types.ERROR_SERVICE, err);
      commit(Types.SET_LOADING, false);
    }
  },
  // resetMenu(context) {
  //   let data = state.modules;
  //   data.forEach(function(element) {
  //     element.enable = false;
  //     if (element.submenus.length > 0) {
  //       element.submenus.forEach(function(e) {
  //         e.enable = false;
  //         if (e.slug == "role-management") {
  //           e.restricted = true;
  //         } else {
  //           e.restricted = false;
  //         }
  //       });
  //     }
  //   });
  //   context.commit(Types.SET_MODULES, data);
  // },
  setEditRole({ commit }, payload) {
    commit(Types.SET_ROLE, payload);
  },
  async getRoleMenus(context, payload) {
    try {
      context.commit(Types.SET_LOADING, true);
      const response = await this.$axiosNoLoading.get(
        `api/role/${payload}/show`
      );
      context.commit(Types.SET_ROLE_MENU, response.data);
      context.commit(Types.SET_LOADING, false);
    } catch (err) {
      context.commit(Types.ERROR_SERVICE, err);
      context.commit(Types.SET_LOADING, false);
    }
  },
  async insertDefaultRole({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await this.$axios.post(
        `${process.env.VUE_APP_AUTH_URL}/api/role/insert-default-role`,
        {
          ...payload,
        }
      );
      commit(Types.SET_LOADING, false);
    } catch (e) {
      console.log("Error", e);
      commit(Types.SET_LOADING, false);
    }
  },
  async getRolesPaginate(context, payload) {
    this.getRolePaginate(payload);
  },
  async insertDefaultV3Role({ commit }, payload) {
    commit(Types.SET_LOADING, true);
    try {
      await RoleService.insertDefaultV3Role(payload);
      commit(Types.SET_LOADING, false);
    } catch (e) {
      console.log("Error", e);
      commit(Types.SET_LOADING, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
